const colors = {

    // Primary
    prussian: '#2d344b',
    orange: '#f36e21',
    peach: '#faa918',
    jungle: '#3a833c',
    tomato: '#e06c4f',
    brown: '#92613a',

    // Secondary
    gold: '#f7ce6a',
    blonde: '#fffae5',
    leaf: '#61c16c',
    mint: '#e6f4e9',

    // Neutral
    slate: '#707070',
    stone: '#b5b5b5',
    silver: '#d9d9d9',
    mercury: '#e6e6e6',
    platinum: '#f5f5f5',
    white: '#ffffff',

    // Special
    soil: '#814026',
    dragon: '#ac0b1f',
    rose: '#ffebe5',
    lightPrussian: '#f0f2f6',

}

export default colors
