import * as React from 'react'
import { Component } from 'react'
import { TextField as MuiTextField } from '@material-ui/core'
import { TextFieldProps } from '@material-ui/core/TextField'
import { css, cx } from 'emotion'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import PasswordRequirements from './PasswordRequirements'

const classNames = {
    textField: css`
        height: 80px;
        margin-bottom: 8px;
    `
}

type Props = TextFieldProps & {
    showRequirements?: boolean
}
interface State {
    showPassword: boolean
    touched: boolean,
    focused: boolean,
    value: string
}

class PasswordField extends Component<Props, State> {
    static defaultProps = {
        showRequirements: false,
    }

    state = {
        showPassword: false,
        touched: false,
        focused: false,
        value: '',
    }

    handleVisibilityToggleClick = () => {
        this.setState(prevState => ({
            showPassword: !prevState.showPassword
        }))
    }

    handleChange = (e: any) => {
        this.setState({ value: e.target.value })
        if (this.props.onChange) {
            this.props.onChange(e)
        }
    }

    handleFocus = (e: any) => {
        this.setState({ touched: true, focused: true })
        if (this.props.onFocus) {
            this.props.onFocus(e)
        }
    }

    handleBlur = (e: any) => {
        this.setState({ focused: false })
        if (this.props.onBlur) {
            this.props.onBlur(e)
        }
    }

    render() {
        const {
            className,
            onChange,
            onFocus,
            onBlur,
            showRequirements,
            helperText,
            ...other
        } = this.props
        const {
            showPassword,
            value,
            touched,
            focused,
        } = this.state

        return (
            <>
                <MuiTextField
                    {...other}
                    className={className ? cx(className, classNames.textField) : classNames.textField}
                    onChange={this.handleChange}
                    onFocus={this.handleFocus}
                    onBlur={this.handleBlur}
                    helperText={showRequirements ? '' : helperText}
                    InputProps={{
                        type: showPassword ? 'text' : 'password',
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="Toggle password visibility"
                                    onClick={this.handleVisibilityToggleClick}
                                >
                                    {showPassword ? <Visibility/> : <VisibilityOff/>}
                                </IconButton>
                            </InputAdornment>
                        )}
                    }
                />
                {showRequirements && touched && <PasswordRequirements touched={touched} focused={focused} password={value} />}
            </>
        )
    }
}

export default PasswordField
