import * as React from 'react'
import { components, colors, elements } from '@peachjar/components'
import { css, cx } from 'react-emotion'

import withLoading from '../withLoading/withLoading'
import { DeclineReason } from '../../pages/AllApprovals/approvals.duck'

const { Modal, Dropdown } = components
const { GreyBox } = elements
const { Paragraph, Note } = elements.typography
const { ButtonFlatLarge } = components.Buttons

const ButtonFlatLargeWithLoading = withLoading(ButtonFlatLarge)

type Props = {
  closeModal: () => void,
  onSubmit: () => void,
  onReasonChange: (event: any) => void,
  onReasonExplanationChange: (
    event: any
  ) => void,
  reason: DeclineReason,
  reasonExplanation?: string,
  isSubmitButtonLoading?: boolean,
  sellerCompanyName?: string,
  districtName?: string,
  reasonNames: { value?: string, label?: string }[],
  reasonVerbiages: { [key: string]: string },
  isDenialMode: boolean,
}

const FlyerReasonModal = (props: Props) => {
  const {
    closeModal,
    onSubmit,
    onReasonChange,
    onReasonExplanationChange,
    reason,
    reasonExplanation,
    isSubmitButtonLoading,
    sellerCompanyName: flyerTitle,
    districtName = '',
    reasonNames,
    reasonVerbiages,
    isDenialMode,
  } = props
  const hasDeletionReason = reason !== null && reason !== undefined

  // TODO: unify these testIds - no real reason for them to be different
  // anymore, but I'm not sure if there are external references to these ids
  const reasonSelectTestId = isDenialMode
    ? 'click-dropdown-denialReason'
    : 'click-dropdown-deletionReason'
  const cancelButtonTestId = isDenialMode
    ? 'click-cancel-stageDenialReason'
    : 'click-cancel-submitDeletionReason'
  const submitButtonTestId = isDenialMode
    ? 'submit-stageDenialReason'
    : 'submit-deletionReason'
  const explanationInputTestId = isDenialMode
    ? 'field-denialReasonExplanation'
    : 'field-deletionReasonExplanation'

  // Text differences between the 2 modes
  const submitButtonText = isDenialMode ? 'Confirm' : 'Remove'
  const headerText = isDenialMode ? 'Denial Reason' : 'Post Removal Reason'
  const emailSubject = isDenialMode
    ? 'Your flyer has been denied'
    : "Your flyer's status has been changed from approved to denied."
  const emailIntro = isDenialMode
    ? 'It looks like your flyer was not approved.'
    : "Your flyer's status has been changed from approved to denied."
  const initialText = isDenialMode
    ? `Confirm you want to deny flyer
      "${flyerTitle}" by selecting a reason below.`
    : `By removing this posting, any future email distributions will
      not be sent and "${flyerTitle}" Flyer will be removed from the flyerboard. This action
      will not affect any past email distributions.`
  const emailNotificationText = isDenialMode
    ? 'The following email notification will be sent to the uploader. You can add additional notes in the space provided if needed.'
    : 'The following email notification will be sent to the uploader upon removal. You can add to the email message using the space provided below.'
  const submitFlyerText = isDenialMode
    ? `Thank you for using Peachjar to submit your flyer to schools. Unfortunately, one or more schools ${districtName ? `at ${districtName}` : ''} were unable to approve your flyer.`
    : `Thank you for using Peachjar to submit your flyer to schools. Unfortunately, one or more schools ${districtName ? `at ${districtName}` : ''} have removed your flyer.`

  return (
    <Modal width="md" close={closeModal}>
      <Modal.Header color={colors.rose}>{headerText}</Modal.Header>
      <Modal.Body className="pt-3">
        <Paragraph className={classNames.blockText}>{initialText}</Paragraph>
        <Dropdown
          label={isDenialMode ? 'Denial Reason' : 'Post Removal Reason'}
          data-testid={reasonSelectTestId}
          options={reasonNames}
          onChange={onReasonChange}
          value={reason || ''}
        />
        {hasDeletionReason && (
          <React.Fragment>
            <Paragraph
              className={cx(classNames.blockText, classNames.marginTop)}
            >
              {emailNotificationText}
            </Paragraph>
            <GreyBox>
              <Paragraph className={classNames.blockText}>
                {submitFlyerText}
              </Paragraph>
              <h4 className={classNames.fieldLabel}>
                <Paragraph bold>
                  {`${isDenialMode ? 'Denial' : 'Removal'}`} Reason:
                </Paragraph>
              </h4>
              <Paragraph className={classNames.blockText}>
                {reasonVerbiages[reason]}
              </Paragraph>
              <textarea
                placeholder="Add additional notes (optional)"
                value={reasonExplanation || ''}
                onChange={onReasonExplanationChange}
                data-testid={explanationInputTestId}
                className={classNames.textarea}
              />
              {isDenialMode && (
                <>
                  <Paragraph className={classNames.blockText}>
                    Refer to our district's approval guidelines for more
                    details. If you believe this denial may have happened in
                    error, please contact the school district directly.
                  </Paragraph>
                  <Paragraph muted>
                    Note, your Peachjar credits will be automatically returned
                    to your account within 24 hours.
                  </Paragraph>
                </>
              )}
              {!isDenialMode && (
                <>
                  <Paragraph className={classNames.blockText}>
                    If you believe this removal may have happened in error,
                    please contact the school district directly.
                  </Paragraph>
                  <Paragraph muted>
                    If your flyer hasn't been distributed yet, your Peachjar
                    credits will be automatically returned to your account
                    within 24 hours. Please call us at (858) 997-2117 to return
                    your Peachjar credits to your account. If your flyer was
                    active, it is now removed from the school's web flyerboard.
                    This does not affect any flyer emails that have been sent.
                  </Paragraph>
                </>
              )}
            </GreyBox>
          </React.Fragment>
        )}
      </Modal.Body>
      <Modal.Footer
        className={cx(hasDeletionReason && classNames.footerShadow)}
      >
        <ButtonFlatLarge data-testid={cancelButtonTestId} onClick={closeModal}>
          Cancel
        </ButtonFlatLarge>
        <ButtonFlatLargeWithLoading
          data-testid={submitButtonTestId}
          disabled={!hasDeletionReason}
          loading={!!isSubmitButtonLoading}
          // @ts-ignore
          onClick={onSubmit}
        >
          {submitButtonText}
        </ButtonFlatLargeWithLoading>
      </Modal.Footer>
    </Modal>
  )
}

const classNames = {
  icon: css`
    font-size: 1.5rem;
  `,
  fieldLabel: css`
    margin-bottom: 0.5rem;
  `,
  blockText: css`
    display: block;
    margin-bottom: 1.5rem;
  `,
  marginTop: css`
    margin-top: 1.5rem;
  `,
  footerShadow: css`
    margin: 0 !important;
    padding: 1rem;
    box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.1);
  `,
  textarea: css`
    width: 100%;
    min-height: 5.875rem;
    display: block;
    margin-bottom: 1.5rem;
    padding: 1.5rem;
    border: 1px solid ${colors.silver};
    border-radius: 3px;
  `,
}

export default FlyerReasonModal
