interface ConfigProps {
    ENVIRONMENT: string
    REACT_APP_LINK_OUT_URL: string
}

function getConfig(domain: string): ConfigProps {
    switch (domain) {
        case 'portal.peachjar.com':
            return {
                ENVIRONMENT: 'production',
                REACT_APP_LINK_OUT_URL: 'https://portal-bff.peachjar.com/linkout'
            }
        case 'portal.peachjar-kauai.com':
            return {
                ENVIRONMENT: 'staging',
                REACT_APP_LINK_OUT_URL: 'https://portal-bff.peachjar-kauai.com/linkout'
            }
        default:
            return {
                ENVIRONMENT: 'development',
                REACT_APP_LINK_OUT_URL: 'https://portal-bff.peachjar.net:10443/linkout'
            }
    }
}

export default getConfig(window.location.hostname)
