import React, { useContext, useState } from 'react'
import idx from 'idx'
import { connect } from 'react-redux'
import { useQuery } from '@apollo/react-hooks'
import { flags } from '@peachjar/components'
// import getUserRanking from '../../utils/getUserRanking/getUserRanking'
import UserContext from '../../middleware/context/UserContext'
// import approvalsRoleConfig from '../../config/approvalsRoleConfig'
import areRegionsMultiDistrict from '../AllApprovals/utils/areRegionsMultiDistrict'
import { getNumberOfStagedFlyers } from '../AllApprovals/utils/selectors'
import { GET_PENDING_APPROVALS } from './PendingApprovals.graphql'
import PendingApprovals from './PendingApprovals'
import { APP_ROUTES } from '../../config/constants'

type ReduxStateProps = {
  numberOfStagedApprovals: number;
}

const { getFeatureFlag } = flags

const PendingApprovalsContainer = ({ numberOfStagedApprovals, ...rest }) => {
  // @ts-ignore
  const { userData } = useContext(UserContext)
  const [ selectedRegionName, setSelectedRegionName ] = useState(null)
  const [queryVariables, setQueryVariables] = useState({
    PendingApprovals_sodUser_sod: null,
    PendingApprovals_sodUser_sodId: null
  })
  const {
    loading: loadingPendingApprovals,
    data: approvalsData,
    error: approvalsError,
    refetch: refetchPendingApprovals,
    networkStatus
  } = useQuery(GET_PENDING_APPROVALS, {
    variables: {
      ...queryVariables
    }
  })

  const changeRegion = (sod: any, sodId: number, regionName: string): void => {
    // @ts-ignore
    setSelectedRegionName(regionName)
    // @ts-ignore
    setQueryVariables({
      PendingApprovals_sodUser_sod: sod,
      PendingApprovals_sodUser_sodId: sodId.toString()
    })
  }

  if (loadingPendingApprovals) {
    return null
  }

  if (approvalsError) {
    window.__PJ__.logger.capture(approvalsError)
  }

  const approvalCenterRegions = idx(approvalsData.sodUser, _ => _.approvalCenterRegions) || []
  const defaultRegionName = idx(approvalCenterRegions, _ => _[0].name) || ''

  const mergedProps = {
    sodUser: userData.sodUser,
    regions: approvalCenterRegions,
    adminLevel: idx(userData.sodUser, _ => _.permission.adminLevel) || null,
    approverLevel: idx(userData.sodUser, _ => _.permission.approverLevel) || null,
    flyers: idx(approvalsData.sodUser, _ => _.flyers) || [],
    flyerReasons: idx(approvalsData, _ => _.flyerReasons) || [],
    defaultRegionName,
    shouldUserSeeRegionSelection: approvalCenterRegions.length > 1,
    shouldUserSeeApproverNotesButton: !areRegionsMultiDistrict(approvalCenterRegions),
    changeRegion,
    selectedRegionName,
    numberOfStagedApprovals,
    breadCrumbLink: APP_ROUTES.approvals.index,
    isPortalNavEnabled: getFeatureFlag("portal_nav"),
    loading: loadingPendingApprovals,
    gqlStuff: {
      currentQueryVariables: queryVariables
      // apollHelpers: {
      //   loading: loadingPendingApprovals
      // }
    },
    ...rest
  }

  console.log('num of staged approvals: ', numberOfStagedApprovals)

  // @ts-ignore
  return <PendingApprovals {...mergedProps} />
}

const mapStateToProps = (state): ReduxStateProps => ({
  numberOfStagedApprovals: getNumberOfStagedFlyers(state)
})

export default connect(mapStateToProps)(PendingApprovalsContainer)
