import ApolloClient from 'apollo-boost'
import config from '../../config/config'

const GRAPHQL_URI = config.REACT_APP_GRAPHQL_URI

function createApolloClient({ initialState = {} }) {
  return new ApolloClient({
    uri: GRAPHQL_URI,
    credentials: 'include',
    onError: ({ networkError, graphQLErrors }) => {
      console.log('graphQLErrors', graphQLErrors)
      console.log('networkError', networkError)
    },
  })
}

const apolloClient = createApolloClient({})

export default apolloClient
