import { some } from 'lodash'
import redirectTo from '../redirectTo/redirectTo'

export function isGqlAuthError(error: Error): boolean {
  return (
    error.hasOwnProperty('graphQLErrors') &&
      // @ts-ignore
      some(error.graphQLErrors, e => /requires the user to be authenticated\./.test(e.message))
  )
}

export default function handleGqlAuthError(error: Error) {
  window.__PJ__.logger.capture(error)
  redirectTo()
}
