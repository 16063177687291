import base64url from 'base64-url'
import config from '../../config'

const { REACT_APP_LINK_OUT_URL } = config

interface LinkOutProps {
    url: string
    labels: { [k: string]: string }
    options: { check: boolean; track: boolean; failurl: string }
}

const linkOut = ({
    url,
    labels = { linkType: 'cta' },
    options = { check: true, track: true, failurl: '' }
}: LinkOutProps) => {
    const encodedUrl = base64url.encode(url)
    const encodedLabels = base64url.encode(JSON.stringify(labels))
    const linkOutUrl = `${REACT_APP_LINK_OUT_URL}/${encodedUrl}/${encodedLabels}?track=${
        options.track
    }&check=${options.check}`

    return linkOutUrl
}

export default linkOut
