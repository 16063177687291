import React, { useContext } from 'react'
import getApprovalsSubheadline from './utils/getApprovalsSubheadline'
import getUserRanking from '../../utils/getUserRanking/getUserRanking'
import UserContext from '../../middleware/context/UserContext'
import { UserQuery } from '../../types/userQuery'
import approvalsRoleConfig from '../../config/approvalsRoleConfig'
import Dashboard from './Dashboard'

const DashboardContainer = props => {
  const { userData } = useContext(UserContext) as UserQuery
  // @ts-ignore
  const userRole = getUserRanking(userData.sodUser)
  // @ts-ignore
  const subheadline = getApprovalsSubheadline(userRole)
  const dashboardCardConfig = approvalsRoleConfig[userRole]

  const mergedProps = {
    subheadline,
    dashboardCardConfig,
    ...props
  }

  return <Dashboard {...mergedProps} />
}

export default DashboardContainer
