
import React from 'react'
import gql from 'graphql-tag'
import { filter as gqlFilter } from 'graphql-anywhere'
import { components } from '@peachjar/components'
import { FlyerReason, SodFlyerApprovalHistory } from '../../types/approvals'

import HistoricalApprovals from '../../components/HistoricalApprovals/HistoricalApprovals'
import SubappHeader from '../../components/AppHeader/AppHeader'
import MiniBreadcrumb from '../../components/MiniBreadcrumb/MiniBreadcrumb'
import styles from './AllApprovals.styles'

const { ButtonSecondarySmall } = components.Buttons

type QueryUpdate = {
  offset: number;
  filter: string | null;
}

type Props = {
  sod: string;
  sodRole: string;
  loading: boolean;
  isDistrictAdmin: boolean;
  currentOffset: number;
  flyerReasons: FlyerReason[];
  breadCrumbUrl: string;
  sodFlyerApprovalHistory: SodFlyerApprovalHistory[];
  shouldUserSeeApproverNotesButton: boolean;
  isPortalNavEnabled: boolean;
  updateQueryVariables: (updates: QueryUpdate) => void;
  myApprovalsRouteHandler: () => void;
}

const AllApprovals = ({
  sod,
  sodRole,
  loading,
  flyerReasons,
  breadCrumbUrl,
  isDistrictAdmin,
  currentOffset,
  shouldUserSeeApproverNotesButton,
  sodFlyerApprovalHistory,
  isPortalNavEnabled,
  updateQueryVariables,
  myApprovalsRouteHandler
}) => {
  return (
    <div>
      {isPortalNavEnabled && (
        <MiniBreadcrumb
          linkTo={breadCrumbUrl}
          text="Approvals"
        />
      )}
      <div className={`${isPortalNavEnabled ? styles.sectionHeadline : ''}`}>
        <SubappHeader className={`${isPortalNavEnabled ? styles.subappHeader : ''}`}>All Approvals</SubappHeader>
        { isPortalNavEnabled && sodRole === 'APPROVER' && (
          <div className={styles.toggles}>
            <ButtonSecondarySmall
              data-testid="click-toggle-my-approvals"
              onClick={myApprovalsRouteHandler}
              className={styles.noBorderRadiusRight}
            >
              My Approvals
            </ButtonSecondarySmall>
            <ButtonSecondarySmall
              data-testid="click-toggle-all-approvals"
              disabled={true}
              className={styles.noBorderRadiusLeft}
            >
              All Approvals
            </ButtonSecondarySmall>
          </div>
        )}
      </div>
      <HistoricalApprovals
        flyerList={gqlFilter(
          HistoricalApprovals.fragments.flyerList,
          sodFlyerApprovalHistory || {}
        )}
        flyerReasons={gqlFilter(
          HistoricalApprovals.fragments.flyerReasons,
          flyerReasons,
        )}
        isDistrictAdmin={isDistrictAdmin}
        sod={sod}
        updateQueryVariables={updateQueryVariables}
        loading={loading}
        offset={currentOffset || 0}
        shouldUserSeeApproverNotesButton={shouldUserSeeApproverNotesButton}
      />
    </div>
  )
}

AllApprovals.fragments = {
  sodUser: gql`
    fragment AllApprovals_sodUser on SodUser {
      id
      permission {
        adminLevel
        approverLevel
      }
      approvalCenterRegions {
        districtId
      }
      sodFlyerApprovalHistory(
        limit: $AllApprovals_sodUser_limit
        offset: $AllApprovals_sodUser_offset
        filter: $AllApprovals_sodUser_filter
      ) {
        ...HistoricalFlyerList_flyers
      }
    }
    ${HistoricalApprovals.fragments.flyerList}
  `,
  flyerReasons: gql`
    fragment AllApprovals_flyerReasons on FlyerReason {
      ...HistoricalApprovals_flyerReasons
    }
    ${HistoricalApprovals.fragments.flyerReasons}
  `,
}

export default AllApprovals
