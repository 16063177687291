import * as React from 'react'
import { ReactNode } from 'react'
import { css, cx } from 'emotion'
import colors from '../colors'

const classNames = {
    heading: css`
      font-family: Proxima Nova, Helvetica, Arial, sans-serif;
      font-size: 34px;
      font-weight: 600;
      line-height: 1.29;
      margin-bottom: 24px;
      color: ${colors.prussian};
    `
}

interface Props {
    children: ReactNode
    className?: any
    style?: any
}

const Heading1 = ({children, className, ...other}: Props) => {
    return <h1 className={cx(classNames.heading, className)} {...other}>{children}</h1>
}

export default Heading1
