import { css } from 'emotion'

const noteStyles = `
    font-size: 0.9rem;
    line-height: 1.55;
`

const labelStyles = `
  white-space: normal;
  word-break: break-all;
  overflow: hidden;
`

const infoPopoverClasses = {
  icon: css`
    height: 1rem;
    cursor: pointer;
  `,
  wrapper: css`
    display: inline-block;
    margin-top: -10px;
  `,
  content: css`
    padding: 0;
    width: 310px;
  `,
  orgHeadline: css`
    font-weight: 600;
    font-size: 1.2rem;
    margin-bottom: 4px;
    word-break: break-all;
  `,
  orgLink: css`
    ${noteStyles};
    display: inline-block;
  `,
  note: css`
    ${noteStyles};
    ${labelStyles};
    display: block;
  `,
  section: css`
    margin: 16px 0 0 0;
    padding: 0 25px 0 25px;

    &:last-of-type {
      margin-bottom: 16px;
    }
  `,
  label: css`
    ${noteStyles};
    display: inline-block;
  `,
  dashed: css`
    font-size: 0.8125rem;
    letter-spacing: 1px;
  `,
  iconRoot: css`
    line-height: 1;
    vertical-align: baseline !important;
  `,
  popoverText: css`
    color: #3a833c;
    font-size: 13px;
    cursor: pointer;
    line-height: 1.48;
    vertical-align: bottom;

    &:hover {
      color: #c5e8c9;

      #profileIcon {
        fill: #c5e8c9;
      }
    }

    svg {
      margin-right: 2px;
      height: 18px;
      width: 18px;
      display: inline-block;
      vertical-align: bottom;
    }
  `,
  kicker: css`
    padding: 16px 25px;
    border-bottom: 2px solid #ebebeb;
  `,
}

export default infoPopoverClasses
