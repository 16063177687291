
import * as React from 'react'
import { css, cx } from 'react-emotion'
import { Button } from '@material-ui/core'
import { colors, fonts } from '@peachjar/components'

const { ProximaNova } = fonts

type Props = {
  isActive: boolean,
  onClick: (event: any) => void,
  children: React.ReactNode,
  variant: 'approve' | 'hold' | 'deny' | 'mixed',
  'data-testid': string,
  className?: string,
  style?: { [prop: string]: string | number },
  disabled?: boolean,
}

export const FlyerApprovalButton = ({
  isActive,
  onClick,
  disabled,
  className,
  style,
  children,
  'data-testid': testId,
  variant,
}: Props) => (
  <Button
    color="default"
    style={{ ...style }}
    disabled={disabled}
    className={cx(
      'solution--stop-gap',
      classNames.pjApprovalBtn,
      classNames[variant],
      className,
      isActive && 'isActive'
    )}
    onClick={onClick}
    data-testid={testId}
  >
    {children}
  </Button>
)

FlyerApprovalButton.defaultProps = {
  className: '',
  disabled: false,
  style: {},
}

const classNames = {
  pjApprovalBtn: css`
    &.solution--stop-gap {
      flex: 1 auto !important;
      font-family: ${ProximaNova};
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: none !important;
      text-transform: none;
      font-size: 1rem;
      line-height: 1.5;
      border: solid 1px ${colors.silver};
      border-radius: 0;
      color: ${colors.prussian} !important;
      padding: 1rem;
      margin: 0;
      &:last-child {
        border-bottom-right-radius: 6px !important;
      }
      &:hover {
        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.24),
          0 0 2px 0 rgba(0, 0, 0, 0.12) !important;
        background-color: unset;
      }
    }
  `,
  approve: css`
    &.isActive {
      background-color: ${colors.mint} !important;
    }
  `,
  deny: css`
    &.isActive {
      background-color: ${colors.rose} !important;
    }
  `,
  hold: css`
    &.isActive {
      background-color: ${colors.blonde} !important;
    }
  `,
  mixed: css`
    &.isActive {
      background-color: ${colors.platinum} !important;
    }
  `,
}

export default FlyerApprovalButton
