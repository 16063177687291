const CONSTANTS = Object.freeze({
  APP_NAME: 'mapp-approval-center',
  APP_ROOT_ID: 'root',
  APP_SUB_APP_ROOT_ID: 'root-sub-apps',
})

export const APP_ROUTES = Object.freeze({
  appBasename: '/approvals',
  index: '/',
  approvals: {
    index: '/',
    pending: '/pending',
    history: {
      myApprovals: '/my-approvals',
      allApprovals: '/all-approvals',
    },
  },
})

export const NOTIFICATIONS = {
  errorKey: 'PJ_APP_ERROR',
  roleDenied:
    'Your assigned role does not have access to this area. Contact your district or school administrator for more information.',
  logoutFailed: 'Log out failed. Please try again.',
  removeCampaignSuccess: 'Your draft flyer has been removed.',
  removeCampaignError: 'Failed to remove draft flyer. Please try again.',
  removeFlyerSuccess: 'Your flyer has been removed.',
  removeFlyerError: 'Failed to remove flyer. Please try again.',
  removeFlyerWarning:
    'Flyer can only be removed in Flyer Reporting by the person who uploaded it. Please contact your District Admin to remove the flyer in All Approvals.',
  flyerDetailsInvalid:
    "Sorry, the flyer details you are looking for was moved or doesn't exist. Try one of the flyers in Flyer Reporting to find the flyer details you are looking for.",
  flyerReportInvalid:
    "Sorry, the flyer report you are looking for was moved or doesn't exist. Try one of the flyers in Flyer Reporting to find the flyer report you are looking for.",
  clipboardSuccess: 'Link has been copied to clipboard.',
  clipboardError: 'Failed to copy link to clipboard. Please try again.',
  paymentSuccess:
    'Your purchase is complete. View your transaction history for your most recent purchases.',
  paymentError:
    'Your payment did not go through due to a system error. Please try to submit payment again. If you need assistance contact us at (858) 997-2117.',
  genericError:
    'Something went wrong. Please try again. If you need assistance contact us at (858) 997-2117.',
  accountInfoSaveError: 'Could not update your information. Please try again.',
  accountInfoSaveSuccess: 'Your information has been updated.',
  accountInfoFileUploadSuccess: 'Your file has been uploaded.',
  accountInfoFileUploadError: 'Could not upload your file. Please try again.',
  accountInfoFileRemoveSuccess: 'Your file has been removed.',
  accountInfoFileRemoveError: 'Could not remove your file. Please try again.',
  accountInfoResetPasswordEmailSuccess:
    'A link to reset your password has been emailed to you.',
  accountInfoResetPasswordEmailError:
    'Failed to email a link to reset your password. Please try again.',
  passwordResetSendSuccess:
    'A link to reset your password has been emailed to you.',
  passwordResetSendFailure:
    'Failed to email a link to reset your password. Please try again.',
}

export default CONSTANTS
