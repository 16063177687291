import React from 'react'
import { elements } from '@peachjar/components'
import Grid from '@material-ui/core/Grid'
import ContentContainer from '../../components/ContentContainer/ContentContainer'
import AppHeader from '../../components/AppHeader/AppHeader'
import DashboardCard from '../../components/DashboardCard/DashboardCard'
import styles from './Dashboard.styles'

const { Paragraph } = elements.typography

type Props = {
  subheadline: string;
  dashboardCardConfig: {
    link: string;
    icon: string;
    headline: string;
  }
}

const Dashboard = ({ subheadline, dashboardCardConfig }) => (
  <>
    <AppHeader>Approvals</AppHeader>
    <Paragraph
      className={styles.subheadline}
      data-testid="text-approvals-subheadline"
    >
      {subheadline}
    </Paragraph>
    <ContentContainer>
      <Grid container spacing={3}>
        { dashboardCardConfig.map(({ link, icon, headline }, index) => {
          // @ts-ignore
          return <DashboardCard link={link} icon={icon} headline={headline} key={index} />
        })}
      </Grid>
    </ContentContainer>
  </>
)

export default Dashboard
