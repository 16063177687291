import { registerMicroAppLifeCycles } from '@peachjar/kernel-sanders'
import App from './components/ApplicationWrapper/ApplicationWrapper'
import CONFIG from './config/constants'

const approvalCenterSingleSpaLifeCycles = registerMicroAppLifeCycles({
  rootComponent: App,
  mountTargetId: CONFIG.APP_SUB_APP_ROOT_ID
})

export const bootstrap = [approvalCenterSingleSpaLifeCycles.bootstrap]
export const mount = [approvalCenterSingleSpaLifeCycles.mount]
export const unmount = [approvalCenterSingleSpaLifeCycles.unmount]
