import React, { useState, useContext, useEffect } from 'react'
import idx from 'idx'
import { useQuery } from '@apollo/react-hooks'
import { flags } from '@peachjar/components'
import { GET_ALL_APPROVALS } from './AllApprovals.graphql'
import validateMultiRegionSod from '../../utils/validateMultiRegionSod/validateMultiRegionSod'
import areRegionsMultiDistrict from './utils/areRegionsMultiDistrict'
import getHighestSodLevel from '../../utils/getHighestSodLevel/getHighestSodLevel'
import { APP_ROUTES, NOTIFICATIONS } from '../../config/constants'
import UserContext from '../../middleware/context/UserContext'
import AllApprovals from './AllApprovals'

const { getFeatureFlag } = flags

type Props = {
  history: {
    [key: string]: any;
  }
}

const AllApprovalsContainer = ({history, ...rest}) => {
  // @ts-ignore
  const { userData } = useContext(UserContext)
  const [ queryVariables, setQueryVariables ] = useState({
    AllApprovals_sodUser_limit: 10,
    AllApprovals_sodUser_offset: 0,
    AllApprovals_sodUser_filter: null
  })
  const {
    loading: loadingAllApprovals,
    data: allApprovalsData,
    error: allApprovalsError,
    refetch: refetchAllApprovals,
    networkStatus
  } = useQuery(GET_ALL_APPROVALS, {
    fetchPolicy: 'cache-and-network',
    variables: {
      ...queryVariables
    }
  })

  useEffect(() => {
    refetchAllApprovals(queryVariables)
  }, [queryVariables, refetchAllApprovals])

  if (loadingAllApprovals || networkStatus === 4) {
    return null
  }

  if (allApprovalsError) {
    window.__PJ__.logger.capture(allApprovalsError)
    return NOTIFICATIONS.genericError
  }

  const updateQueryVariables = (updates: { offset: number, filter: string }): void => {
    const { offset, filter } = updates

    const { AllApprovals_sodUser_offset, AllApprovals_sodUser_filter, } = queryVariables
    const newVariables = {
      AllApprovals_sodUser_offset: offset === undefined ? AllApprovals_sodUser_offset : offset,
      AllApprovals_sodUser_filter: filter || AllApprovals_sodUser_filter,
    }

    // @ts-ignore
    setQueryVariables({...queryVariables, ...newVariables})
  }

  const myApprovalsRouteHandler = () =>
    history.push(APP_ROUTES.approvals.history.myApprovals)

  const { sodUser } = userData
  const adminLevel = idx(sodUser, _ => _.permission.adminLevel) || null
  const approverLevel = idx(sodUser, _ => _.permission.approverLevel) || null
  const isDistrictAdmin = adminLevel === "district"
  const sod = validateMultiRegionSod(adminLevel, approverLevel)
  const approvalCenterRegions =
    idx(allApprovalsData, _ => _.sodUser.approvalCenterRegions) || []
  const shouldUserSeeApproverNotesButton = !areRegionsMultiDistrict(
    approvalCenterRegions
  )
  const sodRole = getHighestSodLevel(sodUser)
  const isPortalNavEnabled = getFeatureFlag("portal_nav")

  const mergedProps = {
    sod,
    sodRole,
    loading: loadingAllApprovals,
    flyerReasons: idx(allApprovalsData, _ => _.flyerReasons) || [],
    breadCrumbUrl: APP_ROUTES.approvals.index,
    isDistrictAdmin,
    currentOffset: queryVariables.AllApprovals_sodUser_offset,
    shouldUserSeeApproverNotesButton,
    sodFlyerApprovalHistory: idx(allApprovalsData, _ => _.sodUser.sodFlyerApprovalHistory),
    history,
    isPortalNavEnabled,
    updateQueryVariables,
    myApprovalsRouteHandler,
    ...rest
  }

  return <AllApprovals {...mergedProps} />
}

export default AllApprovalsContainer
