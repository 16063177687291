/**
 * This is a button specific wrapper component that only
 * works for a single use case right now: buttons.
 *
 */
import * as React from "react"
import { css, cx } from "react-emotion"
import CircularProgress from "@material-ui/core/CircularProgress"
import { colors } from "@peachjar/components"

const getDisplayName = (Component: React.ComponentType<any>) =>
  Component.displayName || Component.name || "Component"

const withLoading = (Button: React.ComponentType<any>) => {
  type Props = {
    loading?: boolean;
    disabled?: boolean;
    children: React.ReactNode;
  }

  const WithLoading = (props: Props) => {
    const { loading, disabled, children } = props
    const propsToPass = { ...props }
    delete propsToPass.loading

    return (
      <Button {...propsToPass} disabled={loading || disabled}>
        {loading && (
          <CircularProgress
            color="inherit"
            size="2rem"
            style={{
              color: colors.leaf,
              width: "2rem",
              position: "absolute"
            }}
          />
        )}
        <span className={cx(loading && classNames.buttonTextTransparent)}>
          {children}
        </span>
      </Button>
    )
  }

  WithLoading.defaultProps = {
    loading: false,
    disabled: false
  }

  WithLoading.displayName = `WithLoading(${getDisplayName(Button)})`

  return WithLoading
}

const classNames = {
  buttonTextTransparent: css`
    color: transparent;
  `
}

export default withLoading
