
import * as React from 'react'
import { css } from 'react-emotion'
import { colors, elements, svg } from '@peachjar/components'

const { CircleCheck } = svg
const { MaterialIcon } = elements
const { Paragraph, Note } = elements.typography
const { jungle, slate, dragon } = colors

type Props = {
  approvedCount: number,
  deniedCount: number,
  pendingCount: number,
}

const DistrictStatusBarContent = ({
  approvedCount,
  deniedCount,
  pendingCount,
}: Props) => {
  const hasNoneApproved = approvedCount === 0
  const hasNoneDenied = deniedCount === 0
  const hasNonePending = pendingCount === 0
  return (
    <React.Fragment>
      <CircleCheck size="16" fill={hasNoneApproved ? slate : jungle} />
      <span className={classNames.margin}>
        <Paragraph muted={hasNoneApproved}>{approvedCount}</Paragraph>
        <Paragraph muted={hasNoneApproved} className={classNames.media}>
          {' '}
          Approved
        </Paragraph>
      </span>
      <MaterialIcon
        color={hasNoneDenied ? slate : dragon}
        name="highlight_off"
        className={classNames.buttonIcon}
      />
      <span className={classNames.margin}>
        <Paragraph muted={hasNoneDenied}>{deniedCount}</Paragraph>
        <Paragraph muted={hasNoneDenied} className={classNames.media}>
          {' '}
          Denied
        </Paragraph>
      </span>
      <Note muted={hasNonePending} className={classNames.weNeedAnIconHack}>
        &ndash; &ndash;
      </Note>
      <span className={classNames.margin}>
        <Paragraph muted={hasNonePending}>{pendingCount}</Paragraph>
        <Paragraph muted={hasNonePending} className={classNames.media}>
          {' '}
          Pending
        </Paragraph>
      </span>
    </React.Fragment>
  )
}

const classNames = {
  buttonIcon: css`
    line-height: 0;
  `,
  margin: css`
    margin: 0 1rem 0 0.375rem;
  `,
  media: css`
    @media (max-width: 996px) {
      display: none;
    }
  `,
  weNeedAnIconHack: css`
    line-height: 0;
    min-width: 1.5rem;
    transform: translateY(-0.5px);
  `,
}

export default DistrictStatusBarContent
