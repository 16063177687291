import { css } from 'emotion'

const DashboardStyles = {
  subheadline: css`
    font-size: 16px;
    display: block;
    padding-top: 0.5rem;
  `
}

export default DashboardStyles
