
import * as React from 'react'
import { css } from 'react-emotion'
import { Dashed } from '@peachjar/ui'
import { svg, colors, elements } from '@peachjar/components'

import getFormattedDate from '../../pages/AllApprovals/utils/getFormattedDate'
import { Status } from '../../pages/AllApprovals/approvals.duck'

const { MaterialIcon } = elements
const { Note } = elements.typography
const { CircleCheck } = svg

type Props = {
   status?: Status,
   updatedAt: number,
}

const LastStatus = ({ status, updatedAt }: Props) => {
  if (status === 'approved') {
    return (
      <React.Fragment>
        <span>
          <CircleCheck size="24" fill={colors.jungle} />
        </span>
        <Note className="mt-1 ml-1">
          Last Flyer Approval Status: {getFormattedDate(updatedAt)}
        </Note>
      </React.Fragment>
    )
  }

  if (status === 'denied') {
    return (
      <React.Fragment>
        <span>
          <MaterialIcon
            name="highlight_off"
            color={colors.dragon}
            className={classNames.icon}
          />
        </span>
        <Note className="mt-1 ml-1">
          Last Flyer Approval Status: {getFormattedDate(updatedAt)}
        </Note>
      </React.Fragment>
    )
  }

  return <Note><Dashed testid="flyer-approval-status" /> Last Flyer Approval Status</Note>
}

const classNames = {
  icon: css`
    font-size: 1.5rem;
    line-height: 0;
  `,
}

export default LastStatus
