import * as React from 'react'
import { css } from 'emotion'

interface Props {
  testid: string
}

const Dashed: React.FunctionComponent<Props> = ({ testid = 'generic' }) => (
    <span className={classNames.dashed} data-testid={`text-dash-${testid}`}>
        &ndash;&ndash;
    </span>
)

const classNames = {
  dashed: css`
      font-size: 0.8125rem;
      letter-spacing: 1px;
  `
}

export default Dashed
