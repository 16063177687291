import * as React from 'react'
import { TextField as MuiTextField } from '@material-ui/core'
import { TextFieldProps } from '@material-ui/core/TextField'
import { css, cx } from 'emotion'
import MenuItem from '@material-ui/core/MenuItem'
import { ReactNode } from 'react'

const classNames = {
    textField: css`
        height: 80px;
        margin-bottom: 8px;
    `
}

type SelectOption = {
    description: ReactNode
    value: any
}

type Props = TextFieldProps & {
    options: SelectOption[]
}

const SelectField = (props: Props) => {

    const {
        options,
        ...other
    } = props

    return (
        <MuiTextField
            {...other}
            className={props.className ? cx(props.className, classNames.textField) : classNames.textField}
            select={true}
        >
            {options.map((opt, index) => {
                return <MenuItem value={opt.value} key={index}>{opt.description}</MenuItem>
            })}
        </MuiTextField>
    )
}

export default SelectField
