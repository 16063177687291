import { uniq } from 'lodash'

type Region = {
  districtId: number;
}

export default function areRegionsMultiDistrict(regions: Region[]): boolean {
  // We only show the approver notes button to users with whose regions are all
  // in a single district.  This is so we don't have to decide which district
  // a note should be read/written from.
  return uniq(regions.map(r => r.districtId)).length > 1
}
