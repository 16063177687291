import React from 'react'
import { cx } from 'emotion'
import { elements } from '@peachjar/components'
import styles from './AppHeader.styles'

type Props = {
  children: React.ReactNode;
  className?: any;
}

const { Headline1 } = elements.typography

const AppHeader = ({ children, className }: Props) => (
  <Headline1 className={cx(styles.appHeader, className)}>{children}</Headline1>
)

export default AppHeader
