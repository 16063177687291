export const STRINGS = {
  APPROVER_NOTES_ERROR_MESSAGE:
    'Oops! Your note could not be saved. Please try again.',
  APPROVER_NOTES_SUCCESS_MESSAGE: 'Your note has been saved.',
  MESSAGE_THE_UPLOADER_ERROR_MESSAGE:
    'Oops! Your message was not sent. Please try again.',
  MESSAGE_THE_UPLOADER_SUCCESS_MESSAGE: 'Your message is being sent.',
  DELETE_FLYER_ERROR_MESSAGE:
    'Oops! The status of your flyer has not been updated. Please try again.',
  DELETE_FLYER_SUCCESS_MESSAGE: 'The status of your flyer has been updated.',
  SUMBIT_FLYER_APPROVAL_STATUS_UPDATES_ERROR_MESSAGE:
    'Oops! Your flyers were not submitted. Please try again.',
  SUBMIT_FLYER_APPROVAL_STATUS_UPDATES_SUCCESS_MESSAGE:
    'Your submitted flyers have been received.',
}

export const MAX_ALLOWED_APPROVER_NOTES_CHARACTERS = 750
export const MAX_ALLOWED_APPROVER_MESSAGE_CHARACTERS = 450
export const MAX_ALLOWED_DENIAL_REASON_EXPLANATION_CHARACTERS = 450

export const APPROVAL_STATUSES = {
  APPROVED: 'approved',
  PENDING: 'pending',
  DENIED: 'denied',
}
