/* global window */
import { createStore, compose, applyMiddleware } from 'redux'
import { createLogger } from 'redux-logger'
import thunk from 'redux-thunk'
import rootReducer from '../rootReducer'
import defaultState from './defaultState'

const store = createStore(
  rootReducer,
  defaultState,
  // enhancers
  compose(
    applyMiddleware(thunk, createLogger()),
    window.devToolsExtension ? window.devToolsExtension() : f => f
  )
);

if (module.hot) {
  module.hot.accept('../rootReducer', () => {
    const nextRootReducer = require('../rootReducer').default;
    store.replaceReducer(nextRootReducer);
  })
}

export default store
