import idx from 'idx'

export default function getUserRanking(user) {
  const adminLevel = idx(user, _ => _.permission.adminLevel) || null
  const approverLevel = idx(user, _ => _.permission.approverLevel) || null
  const isAdmin = adminLevel !== null
  const isApprover = approverLevel !== null

  switch (true) {
    case isApprover:
      return 'APPROVER'
    case isAdmin:
      return 'ADMIN'
    default:
      return 'UPLOADER'
  }
}
