import { APP_ROUTES } from './constants'
import pendingApprovalsSVG from '../assets/icon_pendingApprovals.svg'
import approvalHistorySVG from '../assets/icon_approvalHistory.svg'

const approvalsRoleConfig = {
  APPROVER: [
    {
      link: APP_ROUTES.approvals.pending,
      icon: pendingApprovalsSVG,
      headline: "Pending Approvals"
    },
    {
      link: APP_ROUTES.approvals.history.allApprovals,
      icon: approvalHistorySVG,
      headline: "Approval History"
    }
  ],
  ADMIN: [
    {
      link: APP_ROUTES.approvals.history.allApprovals,
      icon: approvalHistorySVG,
      headline: "Approval History"
    },
  ],
  UPLOADER: [
    {
      link: "",
      icon: "",
      headline: ""
    }
  ]
}

export default approvalsRoleConfig
