
import * as React from 'react'
import { components } from '@peachjar/components'

import RegionSelectionModal from '../RegionSelectionModal/RegionSelectionModal'

const { ButtonSecondarySmall } = components.Buttons

type Props = {
   regions: any,
   showModal: (component: React.Component, any: any) => void,
   closeModal: () => void,
   changeRegion: (sod: any, sodId: number, regionName: string) => void,
}

class RegionSelectionButton extends React.Component<Props> {
  static fragments: { [key: string]: any }

  componentDidMount() {
    const { showModal, closeModal, regions, changeRegion } = this.props
    // @ts-ignore
    showModal(RegionSelectionModal, {
      regions,
      changeRegion,
      closeModal,
    })
  }

  render() {
    const { changeRegion, regions, showModal, closeModal } = this.props

    return (
      <ButtonSecondarySmall
        onClick={() =>
          // @ts-ignore
          showModal(RegionSelectionModal, {
            regions,
            changeRegion,
            closeModal,
          })
        }
      >
        Change Site
      </ButtonSecondarySmall>
    )
  }
}

export default RegionSelectionButton
