
import * as React from 'react'
import { css, cx } from 'react-emotion'
import gql from 'graphql-tag'
import idx from 'idx'
import { filter } from 'graphql-anywhere'
import { colors, elements } from '@peachjar/components'
import getFormattedDate from '../../pages/AllApprovals/utils/getFormattedDate'
import FlyerApprovalButton from '../FlyerApprovalButton/FlyerApprovalButton'

import HistoricalApprovalCardStatusBar from '../HistoricalApprovalCardStatusBar/HistoricalApprovalCardStatusBar'
import ApproverMessageModal from '../ApproverMessageModal/ApproverMessageModal'
import ApproverNotesModal from '../ApproverNotesModal/ApproverNotesModal'
import FlyerDetailLightBoxModal from '../FlyerDetailLightBoxModal/FlyerDetailLightBoxModal'
import SchoolRemovalModal from '../SchoolRemovalModal/SchoolRemovalModal'
import generatePreviewFlyerURL from '../../utils/generatePreviewFlyerURL/generatePreviewFlyerURL'
import InfoPopover from '../../components/InfoPopover/InfoPopover'
import { FlyerReason } from '../../types/approvals'

const { Label, Paragraph, Note } = elements.typography
const { MaterialIcon, Tag } = elements

const SECONDS_PER_DAY = 86400
const DAYS_PER_MONTH = 30
const DAYS_PER_PSEUDO_MONTH = 28
const MIN_PSEUDO_MONTHS = 1

const onEnter = (
  callback: Function
  // @ts-ignore
): ((e: SyntheticKeyboardEvent<HTMLElement>) => void) => e => {
  if (e.key === 'Enter') {
    callback()
  }
}

type ModalComponent = React.ComponentType<any>

export type Props = {
  flyer: any,
  flyerReasons: FlyerReason[],
  sod: 'school' | 'district',
  showModal: (ModalComponent, {}) => void,
  closeModal: () => void,
  isDistrictAdmin: boolean,
  shouldUserSeeApproverNotesButton: boolean,
}

class HistoricalApprovalCard extends React.Component<Props> {
  static fragments: { [key: string]: any }

  showFlyerDetailLightBoxModal = () => {
    const { showModal, closeModal, flyer, sod } = this.props
    const approvalData = {
      sod,
      districtId: idx(this.props, _ => _.flyer.approvals[0].districtId) || null,
      schoolId: idx(this.props, _ => _.flyer.approvals[0].schoolId) || null,
      flyerId: idx(this.props, _ => _.flyer.fml) || null
    }
    const previewFlyerURL = generatePreviewFlyerURL(approvalData)

    return showModal(FlyerDetailLightBoxModal, {
      closeModal,
      flyer: filter(FlyerDetailLightBoxModal.fragments.flyer, flyer),
      previewFlyerURL
    })
  };

  showFlyerDetailLightBoxModalOnEnter = onEnter(
    this.showFlyerDetailLightBoxModal
  )

  showSchoolRemovalModal = () => {
    const { showModal, closeModal, flyer, flyerReasons, isDistrictAdmin } = this.props

    return showModal(SchoolRemovalModal, {
      showModal,
      closeModal,
      isDistrictAdmin,
      flyer: filter(SchoolRemovalModal.fragments.flyer, flyer),
      flyerReasons: filter(SchoolRemovalModal.fragments.flyerReasons, flyerReasons),
    })
  };

  showApproverMessageModal = () => {
    const { showModal, closeModal, flyer } = this.props
    return showModal(ApproverMessageModal, {
      closeModal,
      flyer: filter(ApproverMessageModal.fragments.flyer, flyer),
    })
  };

  showApproverNotesModal = () => {
    const { showModal, closeModal, flyer } = this.props
    return showModal(ApproverNotesModal, {
      closeModal,
      flyer: filter(ApproverNotesModal.fragments.flyer, flyer),
    })
  };

  render() {
    const { flyer, sod, shouldUserSeeApproverNotesButton } = this.props
    const {
      id,
      sellerType,
      sellerNote,
      sellerCompanyName,
      sellerTaxId,
      sellerEmail,
      sellerUrl,
      sellerPhone,
      sellerTaxLodUrl,
      title,
      districtPages,
      categoryName,
      submitDate,
      duration,
      endDate,
      eventDate,
      postDate,
      approvals,
    } = flyer

    const schoolCount = approvals.length
    // const durationDays = (endDate - postDate) / SECONDS_PER_DAY;
    // const durationPseudoMonths = Math.max(
    //   MIN_PSEUDO_MONTHS,
    //   Math.floor(durationDays / DAYS_PER_PSEUDO_MONTH)
    // );
    const durationDays = (endDate - postDate) / SECONDS_PER_DAY
    const durationPseudoMonths = Math.ceil(durationDays / DAYS_PER_MONTH)
    // const durationPseudoMonths = Math.ceil(duration / SECONDS_PER_DAY / DAYS_PER_MONTH);
    const lastStatus = approvals[0] && approvals[0].lastStatus
    const lastStatusUpdate = approvals[0] && approvals[0].lastStatusUpdate

    const hasMultiplePages = districtPages.length > 1
    // const hasMultipleSchools = schoolCount > 1;
    const isDistrict = sod === 'district'
    const hasEvent = !!eventDate
    const hasSellerNote =
      typeof sellerNote === 'string' && sellerNote.length > 0

    return (
      <div className={cx(classNames.layoutRow, classNames.card)}>
        {/* Flyer Section */}
        <div className={`${classNames.w42}`}>
          <div className={classNames.layoutRow}>
            <div
              className={cx(`${classNames.flex33} px-3 ${classNames.textCenter} mb-2`, classNames.pointer)}
              onClick={this.showFlyerDetailLightBoxModal}
              onKeyPress={this.showFlyerDetailLightBoxModalOnEnter}
              role="button"
              // @ts-ignore
              tabIndex="0"
            >
              <div className={classNames.thumbImgWrapper}>
                <div className={classNames.flyerImgWrapper}>
                  <img
                    className={classNames.flyerImage}
                    src={idx(districtPages, _ => _[0].cdnImageUrl)}
                    alt={`${title} Thumbnail`}
                  />
                </div>
                {hasMultiplePages && (
                  <div>
                    <Note muted>{districtPages.length} pages</Note>
                  </div>
                )}
              </div>
              <span className={classNames.cardLink}>
                Text Version
              </span>
            </div>
            <div className={`${classNames.flex66} pl-2 pr-3`}>
              <span className={cx('mb-2', classNames.titleWrapper)}>
                <Label bold className={classNames.titleLabel}>
                  {title}
                </Label>
                &nbsp;
                <Note muted className={classNames.flyerIdText}>
                  #{id}
                </Note>
              </span>
              <Tag categoryName={categoryName} />
              <ul className="list-unstyled mt-2">
                <li>
                  <Paragraph>
                    Submitted: {getFormattedDate(submitDate)}
                  </Paragraph>
                </li>
                <li>
                  <Paragraph>
                    Scheduled posting: {getFormattedDate(postDate)}
                  </Paragraph>
                </li>
                <li>
                  <Paragraph>
                    {durationPseudoMonths > 0
                      ? `Distributions: ${durationPseudoMonths}`
                      : `Distribution: ${durationPseudoMonths}`}
                  </Paragraph>
                </li>
                {hasEvent && (
                  <li>
                    <Paragraph>Event:</Paragraph>
                    &nbsp;
                    <Paragraph color={colors.dragon}>
                      {getFormattedDate(eventDate)}
                    </Paragraph>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </div>
        {/* End Flyer Section */}

        <div className={classNames.divider} />

        {/* Uploader Section */}
        <div className={`flex px-3 d-flex flex-column ${classNames.flex58}`}>
          <div className={cx('layout-row', classNames.flexGrow1)}>
            <div className="flex-50">
              <div>
                  <div className={classNames.orgnameContainer}>
                    <div className={classNames.infoTooltipCompensationContainer}>
                      <span className={classNames.forProfitTextAlignment}>
                        <Paragraph bold>{sellerCompanyName}</Paragraph>
                      </span>
                    </div>
                  </div>
                  <div className={classNames.negMarginTop}>
                    <InfoPopover
                      // @ts-ignore
                      iconName="info_outlined"
                      data={{
                        sellerType,
                        sellerTaxId,
                        sellerTaxLodUrl,
                        sellerEmail,
                        sellerUrl,
                        sellerPhone,
                        sellerCompanyName,
                        duration,
                        lastStatus,
                        lastStatusUpdate
                      }}
                    />
                  </div>
              </div>
            </div>
            <div className={cx('flex-50 px-3', classNames.breakWord)}>
              {hasSellerNote && (
                <React.Fragment>
                  <div className="mb-2">
                    <Note bold>Uploader Message:</Note>
                  </div>
                  <Note className="mt-2">{sellerNote}</Note>
                </React.Fragment>
              )}
            </div>
          </div>
          {/* End Uploader Section */}

          {/* Approver Flat Buttons */}
          <div className={classNames.layoutRow}>
            <div className={`${classNames.flex1} mt-2 mb-2`}>
              {shouldUserSeeApproverNotesButton && (
                <a className={classNames.cardLink} onClick={this.showApproverNotesModal} href="javascript:void(0);">Approver Notes</a>
              )}
            </div>
            <div className={`${classNames.flex1} px-3 mt-2 mb-2 ${classNames.msgUploaderLink}`}>
              <a className={classNames.cardLink} onClick={this.showApproverMessageModal} href="javascript:void(0);">Message Uploader</a>
            </div>
          </div>
          {/* End Approver Flat Buttons */}

          {/* Button Group */}
          <div className={cx('layout-row', classNames.rowMargin)}>
            <div className="flex-100 p-0 d-flex">
              <HistoricalApprovalCardStatusBar
                flyer={filter(
                  HistoricalApprovalCardStatusBar.fragments.flyer,
                  flyer
                )}
                isDistrict={isDistrict}
              />

              <FlyerApprovalButton
                isActive
                variant="mixed"
                onClick={this.showSchoolRemovalModal}
                data-testid="click-approvalButton-mixed"
              >
                <MaterialIcon name="list" className={classNames.buttonIcon} />
                &nbsp;
                {isDistrict ? `Schools (${schoolCount})` : 'Details'}
                &nbsp;
                <MaterialIcon
                  name="chevron_right"
                  className={cx(classNames.buttonIcon, classNames.largeIcon)}
                />
              </FlyerApprovalButton>
            </div>
          </div>
          {/* End Button Group */}
        </div>
      </div>
    )
  }
}

HistoricalApprovalCard.fragments = {
  flyer: gql`
    fragment HistoricalApprovalCard_flyer on Flyer {
      id
      title
      categoryName
      sellerType
      sellerNote
      sellerCompanyName
      sellerTaxId
      sellerEmail
      sellerUrl
      sellerPhone
      sellerTaxLodUrl
      submitDate
      postDate
      endDate
      eventDate
      districtPages {
        id
        cdnImageUrl
      }
      approvals {
        id
        lastStatus
        lastStatusUpdate
        isDistributed
        schoolId
        districtId
      }
      ...ApproverMessageModal
      ...ApproverNotesModal
      ...FlyerDetailLightBoxModal
      ...HistoricalApprovalCardStatusBar
      ...SchoolRemovalModal_flyer
    }
    ${ApproverMessageModal.fragments.flyer}
    ${ApproverNotesModal.fragments.flyer}
    ${FlyerDetailLightBoxModal.fragments.flyer}
    ${HistoricalApprovalCardStatusBar.fragments.flyer}
    ${SchoolRemovalModal.fragments.flyer}
  `,
  flyerReasons: gql`
    fragment HistoricalApprovalCard_flyerReasons on FlyerReason {
      ...SchoolRemovalModal_flyerReasons
    }
    ${SchoolRemovalModal.fragments.flyerReasons}
  `,
}

const classNames: { [key: string]: string } = {
  orgnameContainer: css`
    flex-grow: 0;
  `,
  orginfoContainer: css`
    flex-grow: 0;
  `,
  negativeMarginLeft3: css`
    margin-left: -1rem;
  `,
  negMarginTop: css`
    margin-top: -4px;
  `,
  cardLink: css`
    color: ${colors.jungle};
    font-size: 13px;

    &:hover {
      color: #c5e8c9;
      text-decoration: none;
    }
  `,
  w42: css`
    width: 42%;
  `,
  textCenter: css`
    text-align: center;
  `,
  rowMargin: css`
    margin-left: -1rem !important;
    margin-right: -1rem !important;
  `,
  flexGrow1: css`
    flex: 1 auto;
  `,
  titleWrapper: css`
    display: flex;
    max-height: 3em;
    overflow: hidden;
  `,
  titleLabel: css`
    display: flex;
    width: 100%;
  `,
  flyerIdText: css`
    display: flex;
  `,
  flyerImage: css`
    padding-left: 0.25rem;
    padding-right: 0.25rem;
    max-width: 100%;
    max-height: 100%;
    word-break: break-word;
  `,
  flyerImgWrapper: css`
    height: 140px;
    overflow: hidden;
  `,
  // min-height probably not the best way - set it up to match mock for now
  thumbImgWrapper: css`
    width: 100%;
    min-height: 170px;
    text-align: center;
    border-radius: 6px;
    border: solid 1px ${colors.silver};
    padding-top: 0.5rem;
    margin-right: 0;
  `,
  card: css`
    padding-top: 1rem;
    border-radius: 6px;
    box-shadow: 0 0 4px 0 ${colors.stone};
    background-color: white;
  `,
  divider: css`
    outline: 1px solid ${colors.mercury};
    margin-top: -1rem;
  `,
  pointer: css`
    cursor: pointer;
  `,
  link: css`
    text-decoration: underline;
  `,
  stone: css`
    color: ${colors.stone};
  `,
  buttonIcon: css`
    line-height: 0;
  `,
  largeIcon: css`
    font-size: 1.5rem;
  `,
  breakWord: css`
    word-wrap: break-word;
    overflow-wrap: break-word;
    hyphens: auto;
  `,
  infoTooltipCompensationContainer: css`
    // This compensates for iconButton padding and forces it to bleed into gutter (per mock).
    margin-top: 0;
  `,
  nonProfitTextAlignment: css`
    vertical-align: middle;
  `,
  forProfitTextAlignment: css`
    line-height: 1.45;
    word-break: break-all;
  `,
  msgUploaderLink: css`
    padding-left: 2.9rem !important;
  `,
  layoutRow: css`
    display: flex;
  `,
  flex33: css`
    width: 33.33%;
  `,
  flex66: css`
    width: 66.66%;
  `,
  flex58: css`
    width: 58%;
  `,
  flex1: css`
    flex: 1;
  `,
}

export default HistoricalApprovalCard
