import React from 'react'
import { withStyles, Checkbox as MUICheckbox } from '@material-ui/core'

import { colors } from '@peachjar/components'

type StyleProps = {
   classes: { [key: string]: string },
}

type OwnProps = {
   disabled: boolean,
  'data-testid': string,
}

type Props = OwnProps & StyleProps

const Checkbox = (props: Props) => {
  const { classes, 'data-testid': testId, disabled } = props
  const propsToPass = { ...props }
  delete propsToPass.classes

  return (
    <MUICheckbox
      {...propsToPass}
      data-testid={testId}
      // @ts-ignore
      classes={{
        root: !disabled && classes.root,
        checked: classes.checked,
      }}
    />
  )
}

const classes = {
  root: {
    color: colors.slate,
    '&$checked': {
      color: colors.jungle,
    },
  },
  checked: {},
}

export default withStyles(classes)(Checkbox)
