import idx from 'idx'
import { User } from '../../types/user'

export default function isApprovalCenterUser(user: User): boolean {
  const adminLevel = idx(user, _ => _.permission.adminLevel) || null
  const approverLevel = idx(user, _ => _.permission.approverLevel) || null
  const uploaderLevel = idx(user, _ => _.permission.uploaderLevel) || null
  const isAdmin = adminLevel !== null
  const isApprover = approverLevel !== null
  const isUploader = uploaderLevel !== null

  return isAdmin || isApprover || isUploader
}
