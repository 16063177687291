import { css } from 'emotion'

const PendingApprovalsStyles = {
  header: css`
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
  `,
  subheader: css`
    font-weight: normal;
  `,
  subappHeader: css`
    padding-top: 1.75rem !important;
  `,
  headerRow: css`
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.5rem;
  `,
  row: css`
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    &:first-child {
      margin-top: 0 !important;
    }
  `,
  submitNotification: css`
    background: #ebebeb;
    margin: 10px 0 60px 0;
    padding: 26px 40px;
  `,
  submitNotificationText: css`
    color: #707070;
    font-size: 15px;
    padding-top: 15px;
  `
}

export default PendingApprovalsStyles

