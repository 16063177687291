
import * as React from 'react'
import { css, cx } from 'react-emotion'
import { components, elements, colors } from '@peachjar/components'

import { Badge } from '../SidebarNavItemBadge/SidebarNavItemBadge'

const { Modal } = components
const { Paragraph } = elements.typography
const { ButtonFlatSmall } = components.Buttons

const SodEnum = {
  DISTRICT: 'district',
  SCHOOL: 'school',
}

type Props = {
   closeModal: () => void;
   regions: any;
   changeRegion: (sod: any, sodId: number, regionName: string) => void;
}

class RegionSelectionModal extends React.Component<Props> {
  static fragments: { [key: string]: any }

  render() {
    const { closeModal, regions, changeRegion } = this.props
    const approvalCenterRegions = regions.filter(Boolean)

    const { schools, districts } = approvalCenterRegions.reduce(
      (result, nextRegion) => {
        const regionType = nextRegion && nextRegion.type
        if (regionType === SodEnum.DISTRICT) {
          return { ...result, districts: [...result.districts, nextRegion] }
        }
        if (regionType === SodEnum.SCHOOL) {
          return { ...result, schools: [...result.schools, nextRegion] }
        }
        return result
      },
      { schools: [], districts: [] }
    )

    const hasDistricts = districts.length > 0
    const hasSchools = schools.length > 0

    return (
      <Modal width="md" close={closeModal}>
        <Modal.Header>Site Selection</Modal.Header>
        <Modal.Body className="pt-3">
          <Paragraph className={classNames.block}>
            <Paragraph className={classNames.instruction}>
              Choose the Pending Approval region you would like to enter.
            </Paragraph>
            {hasDistricts && (
              <>
                <Paragraph className={classNames.listHeader}>
                  District
                </Paragraph>
                <ul className={cx(classNames.ulReset, classNames.list)}>
                  {districts.map(({ id, name, pendingApprovalCount }) => {
                    const hasNoPendingApprovals = pendingApprovalCount === 0
                    const badgeColor = hasNoPendingApprovals
                      ? colors.silver
                      : '#c5e8c9'
                    return (
                      <li key={id} className={classNames.liReset}>
                        <ButtonFlatSmall
                          className={classNames.listItemButton}
                          onClick={() => {
                            changeRegion('district', id, name)
                            closeModal()
                          }}
                        >
                          <Paragraph link>
                            {name}
                            <Badge
                              color={badgeColor}
                              className={cx(
                                classNames.badge,
                                classNames.badgeFontColor(hasNoPendingApprovals)
                              )}
                            >
                              {pendingApprovalCount}
                            </Badge>
                          </Paragraph>
                        </ButtonFlatSmall>
                      </li>
                    )
                  })}
                </ul>
              </>
            )}
            {hasSchools && (
              <>
                <Paragraph className={classNames.listHeader}>
                  School(s)
                </Paragraph>
                <ul className={cx(classNames.ulReset, classNames.list)}>
                  {schools.map(({ id, name, pendingApprovalCount }) => {
                    const hasNoPendingApprovals = pendingApprovalCount === 0
                    const badgeColor = hasNoPendingApprovals
                      ? colors.silver
                      : '#c5e8c9'

                    return (
                      <li key={id} className={classNames.liReset}>
                        <ButtonFlatSmall
                          className={classNames.listItemButton}
                          onClick={() => {
                            changeRegion('school', id, name)
                            closeModal()
                          }}
                        >
                          <Paragraph link>
                            {name}
                            <Badge
                              color={badgeColor}
                              className={cx(
                                classNames.badge,
                                classNames.badgeFontColor(hasNoPendingApprovals)
                              )}
                            >
                              {pendingApprovalCount}
                            </Badge>
                          </Paragraph>
                        </ButtonFlatSmall>
                      </li>
                    )
                  })}
                </ul>
              </>
            )}
          </Paragraph>
        </Modal.Body>
      </Modal>
    )
  }
}

const classNames = {
  instruction: css`
    display: block;
    margin-bottom: 1.5rem;
  `,
  badge: css`
    min-width: 1rem;
    padding: 0;
    max-height: 1rem;
    min-height: 1rem;
    margin-left: 0.5rem;
  `,
  badgeFontColor: isNoneLeft => css`
    color: ${isNoneLeft ? colors.slate : colors.prussian};
  `,
  block: css`
    display: block;
  `,
  list: css`
    margin-bottom: 1.5rem;
  `,
  listHeader: css`
    display: block;
    margin-bottom: 0.5rem;
  `,
  listItemButton: css`
    &:first-child {
      margin-top: -0.5rem;
    }
  `,
  liReset: css`
    list-style-type: none;
  `,
  ulReset: css`
    list-style-type: none;
    margin-bottom: 0;
    margin-block-start: 0;
    margin-block-end: 0;
    padding-inline-start: 0;
  `,
}

export default RegionSelectionModal
