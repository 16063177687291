import React from 'react'
import { components } from '@peachjar/components'
import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom'
import ApprovalsDashboard from '../../pages/Dashboard/Dashboard.container'
import AllApprovals from '../../pages/AllApprovals/AllApprovals.container'
import PendingApprovals from '../../pages/PendingApprovals/PendingApprovals.container'
import { APP_ROUTES } from '../../config/constants'

type Props = {}

const { NotificationContainer } = components.Notifications
const { ModalRoot } = components
const {
  appBasename,
  approvals: {
    pending: pendingApprovalsRoute,
    history: {
      allApprovals: allApprovalsRoute
    }
  }
} = APP_ROUTES

const App = ({ ...rest }) => {
  const getMergedProps = (routeProps: any) => ({ ...routeProps, ...rest })

  return (
    <>
      <NotificationContainer />
      <ModalRoot />
      <Router basename={appBasename}>
        <Switch>
          <Route
            path={allApprovalsRoute}
            // @ts-ignore
            render={routeProps => <AllApprovals {...getMergedProps(routeProps)} />}
          />
          <Route
            path={pendingApprovalsRoute}
            render={routeProps => <PendingApprovals {...getMergedProps(routeProps)} />}
            />
          <Route
            exact
            path="/"
            render={routeProps => <ApprovalsDashboard {...getMergedProps(routeProps)} />}
            />
        </Switch>
      </Router>
    </>
  )
}

export default App
