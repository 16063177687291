import { css } from 'emotion'

const AppHeaderStyles = {
  appHeader: css`
    padding: 0 0 0.5rem 0;
    margin: 0;
  `
}

export default AppHeaderStyles
