import * as React from 'react'
import { ReactNode } from 'react'
import { css, cx } from 'emotion'
import colors from '../colors'

const classNames = {
    heading: css`
        font-size: 34px;
        font-weight: 600;
        line-height: 1.29;
        font-family: Proxima Nova, Helvetica, Arial, sans-serif;
        font-size: 25px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.28;
        letter-spacing: normal;
        margin-bottom: 8px;
        color: ${colors.prussian};
    `
}

interface Props {
    children: ReactNode
    className?: any
    style?: any
}

const Heading2 = ({children, className, ...other}: Props) => {
    return <h2 className={cx(classNames.heading, className)} {...other}>{children}</h2>
}

export default Heading2
