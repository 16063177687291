/**
 * Determines highest role between adminLevel and approverLevel.
 * Useful for accurately setting SOD level for multi-region users.
 */

const roles = {
  district: {
    label: "district",
    importance: 1
  },
  school: {
    label: "school",
    importance: 0
  }
}

export default function validateMultiRegionSod(adminLevel: string, approverLevel: string): string {
  const { district, school } = roles
  let sod = school.label // default to school

  // return whatever approverLevel is set at if adminLevel is not valid
  if (!adminLevel && approverLevel) {
    return approverLevel.toLowerCase()
  }

  // return whatever adminLevel is set at if approverLevel is not valid
  if (!approverLevel && adminLevel) {
    return adminLevel.toLowerCase()
  }

  const adminLevelString = adminLevel.toLowerCase()
  const approverLevelString = approverLevel.toLowerCase()

  // determine higher role - adminLevel vs. approverLevel
  if (
    adminLevelString === district.label ||
    approverLevelString === district.label
  ) {
    sod = district.label
  }

  return sod
}
