import gql from 'graphql-tag'

export const GET_ALL_APPROVALS = gql`
  query getAllApprovals($limit: Int, $offset: Int, $filter: String) {
    sodUser {
      id
      approvalCenterRegions {
        districtId
      }
      sodFlyerApprovalHistory(limit: $limit, offset: $offset, filter: $filter) {
        pagination {
          total
        }
        items {
          id
          title
          categoryName
          sellerId
          sellerType
          sellerNote
          sellerCompanyName
          sellerTaxId
          sellerEmail
          sellerUrl
          sellerPhone
          sellerTaxLodUrl
          submitDate
          postDate
          endDate
          eventDate
          districtPages {
            id
            cdnImageUrl
            pageNumber
            ocrText
          }
          approvals {
            id
            noteAboutSeller
            lastStatus
            lastStatusUpdate
            isDistributed
            schoolId
            status
            districtId
            districtName
            schoolName
            deletedReason
            deletedReasonExplanation
            deletedByFirstName
            deletedByLastName
            updated
            isAutoApproved
            history {
              id
              status
              denialReason
              denialReasonExplanation
              approverFirstName
              approverLastName
              updated
            }
            pendingFlyerApprovalSequence {
              approverUserId
              approverFirstName
              approverLastName
            }
          }
        }
      }
    }
    flyerReasons {
      code
      displayName
      suggestedVerbiage
      isDeletion
      retiredAt
    }
  }
`
