import { css } from 'emotion'
import { colors } from '@peachjar/components'

// classes
const dashboardStyles = {
  card: css`
    margin-top: 1rem;
  `,
  dashboardCard: css`
    border: 1px solid #e6e6e6;
    padding: 27px 40px 23px 40px;

    &:hover {
      box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2),
        0px 2px 2px 0px rgba(0, 0, 0, 0.14),
        0px 3px 1px -2px rgba(0, 0, 0, 0.12);
    }
  `,
  iconContainer: css`
    text-align: center;
    padding: 2px;
  `,
  cardLink: css`
    &:hover {
      text-decoration: none;
    }
  `,
  headline: css`
    color: ${colors.prussian};
    font-family: 'Proxima Nova', Helvetica, sans-serif !important;
    font-size: 19px;
    padding-top: 12px;
    text-align: center;
  `,
}

export default dashboardStyles
