import * as React from 'react'
import { TextField as MuiTextField } from '@material-ui/core'
import { TextFieldProps } from '@material-ui/core/TextField'
import { css, cx } from 'emotion'

const classNames = {
    textField: css`
        height: 80px;
        margin-bottom: 8px;
    `
}

type Props = TextFieldProps & {}

const TextField = (props: Props) => (
    <MuiTextField
        {...props}
        className={props.className ? cx(classNames.textField, props.className) : classNames.textField}
    />
)

export default TextField
