import React from 'react'
import { filter } from 'graphql-anywhere'
import { components, elements } from '@peachjar/components'
import AppHeader from '../../components/AppHeader/AppHeader'
import ContentContainer from '../../components/ContentContainer/ContentContainer'
import MiniBreadcrumb from '../../components/MiniBreadcrumb/MiniBreadcrumb'
import RegionSelectionButton from '../../components/RegionSelectionButton/RegionSelectionButton'
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner'
import NoPendingApprovalsView from '../../components/NoPendingApprovalsView/NoPendingApprovalsView'
import SubmitPublicationRequestReviewsButton from '../../components/SubmitPublicationRequestReviewsButton/SubmitPublicationRequestReviewsButton'
import ApprovalCard from '../../components/ApprovalCard/ApprovalCard'
import { Flyer, FlyerReason } from '../../types/approvals'
import { User } from '../../types/user'
import styles from './PendingApprovals.styles'

type Props = {
  loading: boolean;
  sodUser: User;
  regions: any;
  flyers: Flyer[];
  flyerReasons: FlyerReason[];
  adminLevel: string | null;
  approverLevel: string | null;
  defaultRegionName: string;
  selectedRegionName: string;
  breadCrumbLink: string;
  numberOfStagedApprovals: number;
  shouldUserSeeRegionSelection: boolean;
  shouldUserSeeApproverNotesButton: boolean;
  isPortalNavEnabled: boolean;
  gqlStuff: {
    currentQueryVariables: any;
    // apolloHelpers: {
    //   loading: boolean;
    // }
  };
  changeRegion: (
    sod: any,
    sodId: number,
    regionName: string
  ) => void;
}

const { ModalConsumer } = components
const { Paragraph } = elements.typography

const PendingApprovals = ({
  sodUser,
  regions,
  flyers,
  loading,
  flyerReasons,
  adminLevel,
  approverLevel,
  breadCrumbLink,
  defaultRegionName,
  selectedRegionName,
  numberOfStagedApprovals,
  shouldUserSeeRegionSelection,
  shouldUserSeeApproverNotesButton,
  isPortalNavEnabled,
  gqlStuff,
  changeRegion,
  ...rest
}: Props) => {

  const sod = approverLevel || 'school'
  const numberOfPendingApprovals = flyers.length
  const isSubmitButtonEnabled = numberOfStagedApprovals > 0 && numberOfStagedApprovals === numberOfPendingApprovals
  const hasApprovalsToShow = numberOfPendingApprovals > 0
  const selectedRegionNameToRender = selectedRegionName || defaultRegionName

  return (
    <ModalConsumer>
      {({ showModal, closeModal }) => (
        <>
          {isPortalNavEnabled && (
            <MiniBreadcrumb linkTo={breadCrumbLink} text="Approvals" />
          )}
          <div className={styles.headerRow}>
            <AppHeader>Pending Approvals</AppHeader>
            {sodUser && shouldUserSeeRegionSelection && (
              <RegionSelectionButton
                regions={regions}
                changeRegion={changeRegion}
                showModal={showModal}
                closeModal={closeModal}
              />
            )}
          </div>
          {shouldUserSeeRegionSelection && (
            <Paragraph className={styles.subheader}>
              {selectedRegionNameToRender}
            </Paragraph>
          )}
          <ContentContainer>
            {loading ? (
              <LoadingSpinner />
            ) : (
              <div>
                {!hasApprovalsToShow ? (
                  <NoPendingApprovalsView />
                ) : (
                  flyers.map(flyer => (
                    <div className={styles.row} key={flyer.fml}>
                      <div>
                          <ApprovalCard
                            flyer={filter(ApprovalCard.fragments.flyer, flyer)}
                            flyerReasons={filter(
                              ApprovalCard.fragments.flyerReasons,
                              flyerReasons
                            )}
                            sod={
                              gqlStuff.currentQueryVariables
                                .PendingApprovals_sodUser_sod || sod
                            }
                            showModal={showModal}
                            closeModal={closeModal}
                            shouldUserSeeApproverNotesButton={
                              shouldUserSeeApproverNotesButton
                            }
                          />
                      </div>
                    </div>
                  ))
                )}

                <div className="layout-row">
                  {hasApprovalsToShow && (
                    <div
                      className={`${styles.submitNotification} layout-row flex`}
                    >
                      <p
                        className={`flex-90 ${styles.submitNotificationText}`}
                      >
                        A decision to Approve, Hold, or Deny is required for{" "}
                        <strong>each flyer</strong> in order to submit.
                      </p>
                      <span className="flex-10">
                        <SubmitPublicationRequestReviewsButton
                          disabled={!isSubmitButtonEnabled}
                          sod={
                            gqlStuff.currentQueryVariables
                              .PendingApprovals_sodUser_sod || sod
                          }
                        >
                          Submit
                        </SubmitPublicationRequestReviewsButton>
                      </span>
                    </div>
                  )}
                </div>
              </div>
            )}
          </ContentContainer>
        </>
      )}
    </ModalConsumer>
  )
}

export default PendingApprovals

