import React from 'react'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import { withStyles } from '@material-ui/core/styles'
import { Link } from 'react-router-dom'
import styles from './DashboardCard.styles'

type Props = {
  link: string;
  icon: string;
  headline: string;
  classes: {
    [key: string]: any
  }
  className: string;
  dataTestId: string;
}

const DashboardCard = ({
  link,
  icon,
  headline,
  classes,
  className,
  dataTestId = "card-dashboard-card"
}: Props) => (
  <Grid item xs={12} sm={4}>
    <div className={styles.card}>
      <div className={`dashboard-card ${className}`} data-testid={dataTestId}>
        <Link to={link} className={styles.cardLink}>
          <Paper className={`${styles.dashboardCard} ${classes.elevation2}`}>
            <div className={styles.iconContainer}>
              <img src={icon} alt="" data-testid="img-dashboard-card-icon" />
            </div>
            <h3
              className={styles.headline}
              data-testid="text-dashboard-card-headline"
            >
              {headline}
            </h3>
          </Paper>
        </Link>
      </div>
    </div>
  </Grid>
)

const muiStyles = {
  elevation2: {
    boxShadow: 'none',
  },
}

export default withStyles(muiStyles)(DashboardCard)
