
import * as React from 'react'
import gql from 'graphql-tag'
import { filter } from 'graphql-anywhere'
import { css } from 'react-emotion'
import idx from 'idx'
// TODO: move entire table to its own component/fragment
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core'

import { components, elements } from '@peachjar/components'

import Checkbox from '../Checkbox/Checkbox'
import DeletionReasonModalContainer from '../DeletionReasonModalContainer/DeletionReasonModalContainer'
import SchoolRemovalModalExpandableTableRow from '../SchoolRemovalModalExpandableTableRow/SchoolRemovalModalExpandableTableRow'

const { Note } = elements.typography
const { ButtonPrimaryLarge, ButtonFlatLarge } = components.Buttons
const { Modal } = components

type Props = {
   closeModal: () => void,
   showModal: (
    component: React.ReactNode,
    props: { [key: string]: any }
  ) => void,
   flyer: any,
   flyerReasons: any,
   isDistrictAdmin: boolean,
}

type State = {
  approvalIdsForDeletion: { [approvalId: string]: boolean },
  isBulkCheckboxSelected: boolean,
  expandedRowApprovalId?: string,
}

class SchoolRemovalModal extends React.Component<Props, State> {
  static fragments: { [key: string]: any }

  // @ts-ignore
  state = {
    approvalIdsForDeletion: {},
    isBulkCheckboxSelected: false,
    expandedRowApprovalId: null,
  }

  expandOrCollapseRow = (approvalId: string) => () => {
    const { expandedRowApprovalId } = this.state
    const isRowExpanded = approvalId === expandedRowApprovalId
    // @ts-ignore
    this.setState({ expandedRowApprovalId: isRowExpanded ? null : approvalId })
  };

  onSubmit = () => {
    const { showModal, closeModal, flyerReasons, flyer } = this.props

    // lol
    const { approvalIdsForDeletion } = this.state
    const districtName = idx(flyer, _ => _.approvals[0].districtName) || ''
    const approvalIds = Object.entries(approvalIdsForDeletion).reduce(
      // @ts-ignore
      (ids, [key, value]) => {
        if (value === true) {
          return [...ids, key]
        }
        return ids
      },
      []
    )
    showModal(DeletionReasonModalContainer, {
      closeModal,
      approvalIdsForDeletion: approvalIds,
      flyerTitle: flyer.title,
      districtName,
      flyerReasons: filter(DeletionReasonModalContainer.fragments.flyerReasons, flyerReasons),
    })
  };

  stageOrUnstageApprovalForRemoval = (id: any) => (
    e: any,
    checked: any
  ): void => {
    const { approvalIdsForDeletion } = this.state
    const newIdsForDeletion = {
      ...approvalIdsForDeletion,
      [id]: checked,
    }
    this.setState({
      approvalIdsForDeletion: newIdsForDeletion,
    })
  };

  bulkStageOrUnstageApprovalForRemoval = (e: any, checked: any): void => {
    const { flyer } = this.props
    const approvals = idx(flyer, _ => _.approvals) || []
    const newApprovalIdsForDeletion = approvals
      .filter(approval => approval.status === 'approved')
      .reduce(
        (result, next) => ({
          ...result,
          [next.id]: checked,
        }),
        {}
      )
    this.setState({
      approvalIdsForDeletion: newApprovalIdsForDeletion,
      isBulkCheckboxSelected: checked,
    })
  };

  render() {
    const { closeModal, isDistrictAdmin, flyerReasons } = this.props
    const {
      approvalIdsForDeletion,
      isBulkCheckboxSelected,
      expandedRowApprovalId,
    } = this.state

    const approvals = idx(this.props, _ => _.flyer.approvals) || []
    const approvalCount = idx(approvals, _ => _.length) || 0

    const numberOfApprovalsAvailableForRemoval = approvals.filter(
      approval => approval.status === 'approved'
    ).length
    const numberOfApprovalsStagedForRemoval = Object.values(
      approvalIdsForDeletion
    ).filter(Boolean).length
    const atLeastOneApprovalIsStaged = numberOfApprovalsStagedForRemoval > 0
    const isBulkCheckboxIndeterminate =
      numberOfApprovalsAvailableForRemoval !==
        numberOfApprovalsStagedForRemoval && atLeastOneApprovalIsStaged
    const isBulkCheckboxDisabled =
      numberOfApprovalsAvailableForRemoval < 1 || !isDistrictAdmin

    return (
      <Modal width="md" close={closeModal}>
        <Modal.Header>
          {`${approvalCount} ${approvalCount > 1 ? 'Schools' : 'School'}`}
        </Modal.Header>
        <Modal.Body className="pt-3">
          <Table className="border rounded">
            <TableHead>
              <TableRow>
                <TableCell>
                  <Note>Name</Note>
                </TableCell>
                <TableCell className={classNames.center}>
                  <Note>Status</Note>
                </TableCell>
                <TableCell>
                  <Note>Date</Note>
                </TableCell>
                <TableCell className="border-right">
                  <Note>Reason</Note>
                </TableCell>
                <TableCell className={classNames.checkboxCell}>
                  <div>
                    <Note muted={isBulkCheckboxDisabled}>Remove Post</Note>
                  </div>
                  <div className={classNames.compressedCheckbox}>
                    <Checkbox
                      // @ts-ignore
                      checked={isBulkCheckboxSelected || false}
                      indeterminate={isBulkCheckboxIndeterminate}
                      onChange={this.bulkStageOrUnstageApprovalForRemoval}
                      value="bulkCheckbox"
                      disabled={isBulkCheckboxDisabled}
                    />
                  </div>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {approvals.map((approval, i) => {
                const isOddRow = i % 2 === 0 // 1st, 3rd, 5th, ..., Nth row
                const approvalId = idx(approval, _ => _.id)
                const isRowExpanded = expandedRowApprovalId === approvalId
                const isCheckboxSelected =
                  approvalIdsForDeletion[approvalId] || false
                // TODO: pick a pattern for filtering items from rows (filter boolean, filter if no id, etc.)
                return (
                  <SchoolRemovalModalExpandableTableRow
                    key={approvalId}
                    approval={approval}
                    stageOrUnstageApprovalForRemoval={
                      this.stageOrUnstageApprovalForRemoval
                    }
                    isRowExpanded={isRowExpanded}
                    isCheckboxSelected={isCheckboxSelected}
                    expandOrCollapseRow={this.expandOrCollapseRow(approvalId)}
                    hasDarkBackground={isOddRow}
                    isDistrictAdmin={isDistrictAdmin}
                    flyerReasons={filter(DeletionReasonModalContainer.fragments.flyerReasons, flyerReasons)}
                  />
                )
              })}
            </TableBody>
          </Table>
        </Modal.Body>
        <Modal.Footer className={classNames.footerShadow}>
          <ButtonFlatLarge
            onClick={closeModal}
            data-testid="click-cancel-schoolRemoval"
          >
            Cancel
          </ButtonFlatLarge>
          <ButtonPrimaryLarge
            data-testid="submit-schoolRemoval"
            onClick={this.onSubmit}
            disabled={!atLeastOneApprovalIsStaged}
          >
            Submit
          </ButtonPrimaryLarge>
        </Modal.Footer>
      </Modal>
    )
  }
}

const classNames = {
  footerShadow: css`
    box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.1);
    padding: 1rem;
    margin: 0 !important;
  `,
  center: css`
    text-align: center !important;
  `,
  checkboxCell: css`
    padding-top: 10px !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
  `,
  compressedCheckbox: css`
    margin: -10px 0px -5px 0px !important;
  `,
}

SchoolRemovalModal.fragments = {
  flyer: gql`
    fragment SchoolRemovalModal_flyer on Flyer {
      id
      title
      approvals {
        id
        districtName
        status
        ...SchoolRemovalModalExpandableTableRow_approval
      }
    }
    ${SchoolRemovalModalExpandableTableRow.fragments.approval}
  `,
  flyerReasons: gql`
    fragment SchoolRemovalModal_flyerReasons on FlyerReason {
      ...DeletionReasonModalContainer_flyerReasons
      ...SchoolRemovalModalExpandableTableRow_flyerReasons
    }
    ${DeletionReasonModalContainer.fragments.flyerReasons}
    ${SchoolRemovalModalExpandableTableRow.fragments.flyerReasons}
  `,
}

export default SchoolRemovalModal
