import idx from 'idx'
import { User } from '../../types/user'

/**
 * Approval center user roles can vary in importance depending on subapp.
 * This helper returns the highest role based off sodUser object.
 * Helpful for determining UI rendering for admins vs approvers vs uploaders.
 */
export default function getHighestSodLevel(sodUser: User): string {
  const adminLevel = idx(sodUser, _ => _.permission.adminLevel) || null
  const approverLevel = idx(sodUser, _ => _.permission.approverLevel) || null

  const isAdmin = adminLevel !== null
  const isApprover = approverLevel !== null

  switch (true) {
    case isApprover:
      return 'APPROVER'
    case isAdmin:
      return 'ADMIN'
    default:
      return 'UPLOADER'
  }

}
