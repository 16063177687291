import gql from 'graphql-tag'

const GET_USER = gql`
  query GET_USER {
    sodUser {
      id
      firstName
      lastName
      permission {
        approverLevel
        adminLevel
        uploaderLevel
      }
    }
  }
`

export default GET_USER
