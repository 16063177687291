import * as React from 'react'
import { useQuery } from '@apollo/react-hooks'
import { css, cx } from 'emotion'
import idx from 'idx'
import { elements } from '@peachjar/components'
import { User } from '../../types/user'
import { GET_PENDING_APPROVALS_COUNT } from "../../pages/PendingApprovals/PendingApprovals.graphql"

// const { Badge } = components;
const { Note } = elements.typography

type BadgeProps = {
  children: string | number;
  color?: string;
  className?: string;
}

export const Badge = ({ color, children, className }: BadgeProps) => (
  <Note className={cx(classNames.badge(color), className)}>{children}</Note>
)

const classNames = {
  badge: color => css`
    display: inline-block;
    padding: 0.125rem 0.25rem;
    background-color: ${color};
    border-radius: 10px;
    margin-left: 0.25rem;
    font-weight: normal;
    margin-top: -0.125rem;
    margin-bottom: -0.125rem;
    min-width: 1.5rem;
    text-align: center;
  `
}

Badge.defaultProps = {
  color: "#c5e8c9", // Some variation on mint
  className: ""
}

type Props = {
  sodUser: User;
}

const SidebarNavItemBadge = () => {
  // const requestsCount = (idx(sodUser, _ => _.requests) || []).length;
  // if you have access to multiple regions, show the total
  // does a user always have access to one region, or would this be an empty list?
  const { loading, data, error } = useQuery(GET_PENDING_APPROVALS_COUNT)
  const regions = idx(data.sodUser, _ => _.approvalCenterRegions) || []
  const totalRequestsCount = regions.reduce(
    (total, { pendingApprovalCount }) => total + pendingApprovalCount,
    0
  )

  if (loading) {
    return null
  }

  if (error) {
    window.__PJ__.logger.capture(error)
  }

  return <Badge>{totalRequestsCount}</Badge>
}

export default SidebarNavItemBadge
