import * as React from 'react'
import { css, cx } from 'react-emotion'
import { TableCell, TableRow } from '@material-ui/core'
import { colors, elements } from '@peachjar/components'

const { Note } = elements.typography

const BodyCell = ({
  children,
  className,
  ...rest
}: {
  children?: React.ReactNode,
  className?: string,
}) => (
  <TableCell {...rest} className={cx(className, classNames.noBorderBottom)}>
    {children}
  </TableCell>
)
BodyCell.defaultProps = {
  className: '',
}

type RowProps = {
  nameCellContent: React.ReactNode, // string name indented, or string name plus arrow
  statusCellContent: React.ReactNode, // status icon only
  dateCellContent: React.ReactNode, // date string or pending icon
  declineReasonCellContent?: React.ReactNode, // reason or null
  checkboxCellContent?: React.ReactNode, // null or some form of checkbox
  hasDarkBackground?: boolean,
  shouldNotShowCheckboxBorder?: boolean,
  hasTopDivider?: boolean,
}
const Row = ({
  nameCellContent,
  statusCellContent,
  dateCellContent,
  declineReasonCellContent,
  checkboxCellContent,
  hasDarkBackground,
  shouldNotShowCheckboxBorder,
  hasTopDivider,
}: RowProps) => (
  <>
    {hasTopDivider ? (
      <tr>
        <td colSpan={5}>
          <div className={classNames.specialTopBorder} />
        </td>
      </tr>
    ) : null}
    <TableRow className={cx(hasDarkBackground && classNames.darkRow)}>
      <BodyCell>
        <div className={classNames.minWidth}>{nameCellContent}</div>
      </BodyCell>
      <BodyCell className={classNames.center}>{statusCellContent}</BodyCell>
      <BodyCell className={classNames.dateCellContent}>
        <Note>
          {dateCellContent}
          {/* could render pending icon as a fallback */}
        </Note>
      </BodyCell>
      <BodyCell>{declineReasonCellContent}</BodyCell>
      <BodyCell
        className={cx(
          // @ts-ignore
          checkboxCellContent && !shouldNotShowCheckboxBorder && 'border-left',
          checkboxCellContent &&
            !shouldNotShowCheckboxBorder &&
            classNames.checkboxCell
        )}
      >
        {checkboxCellContent}
      </BodyCell>
    </TableRow>
  </>
)

Row.defaultProps = {
  declineReasonCellContent: null,
  checkboxCellContent: null,
  hasDarkBackground: false,
  shouldNotShowCheckboxBorder: false,
  hasTopDivider: false,
}

const classNames = {
  darkRow: css`
    background-color: ${colors.platinum};
  `,
  center: css`
    text-align: center !important;
  `,
  dateCellContent: css`
    min-width: 145px;
  `,
  minWidth: css`
    min-width: 100px;
  `,
  checkboxCell: css`
    padding-top: 10px !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
  `,
  specialTopBorder: css`
    height: 1px;
    border-top: 1px dotted ${colors.mercury};
    margin: 0 2rem;
  `,
  noBorderBottom: css`
    border-bottom: 0px !important;
  `,
}

export default Row
