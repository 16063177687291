import { css } from 'emotion'

const AllApprovalsStyles = {
  sectionHeadline: css`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  `,
  subappHeader: css`
    padding-top: 1.75rem !important;
  `,
  toggles: css`
    margin-top: -9px;
  `,
  noBorderRadiusRight: css`
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  `,
  noBorderRadiusLeft: css`
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  `
}

export default AllApprovalsStyles
