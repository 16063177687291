import config from '../../config/config'

const { REACT_APP_PARENT_APP_URL } = config

const generatePreviewFlyerURL = ({ sod, flyerId, districtId, schoolId }) => {
  if (sod && sod.toLowerCase() === 'district') {
    return `${REACT_APP_PARENT_APP_URL}/flyers/${flyerId}/districts/${districtId}`
  } else {
    return `${REACT_APP_PARENT_APP_URL}/flyers/${flyerId}/schools/${schoolId}`
  }
}

export default generatePreviewFlyerURL
