import * as React from 'react'
import gql from 'graphql-tag'
import { filter } from 'graphql-anywhere'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import { css } from 'react-emotion'
import idx from 'idx'
import { components } from '@peachjar/components'

import DenialReasonModalContainer from '../DenialReasonModalContainer/DenialReasonModalContainer'
import SchoolApprovalSelectionTable from '../SchoolApprovalSelectionTable/SchoolApprovalSelectionTable'
import { setApprovalStatus } from '../../pages/AllApprovals/approvals.duck'
import { Status, StagedFlyer, DeclineReason } from '../../pages/AllApprovals/approvals.duck'
import { getStagedFlyers } from '../../pages/AllApprovals/utils/selectors'

const { ButtonPrimaryLarge, ButtonFlatLarge } = components.Buttons
const { Modal } = components

type ReduxDispatchProps = {
   onStageFlyer: (flyer: StagedFlyer) => void,
}

type ReduxStateProps = {
  // Staged Flyer?
   stagedFlyerApproval: {
    approvals: {
      [approvalId: string]: Status,
    },
    denialReason?: DeclineReason,
  },
}

type OwnProps = {
   closeModal: () => void,
   showModal: (
    component: React.Component,
    props: { [key: string]: any }
  ) => void,
   flyer: any,
   flyerReasons: any[] // SchoolApprovalModal_flyerReasons[],
}

type Props =
  OwnProps
  & ReduxDispatchProps
  & ReduxStateProps

type State = {
  [approvalId: string]: Status,
}

class SchoolApprovalModal extends React.Component<Props, State> {
  static fragments: { [key: string]: any }

  // Initialize state (we don't care about prop updates, so this is...OK)
  state =
    idx(this.props, _ => _.stagedFlyerApproval.approvals) ||
    (idx(this.props, _ => _.flyer.approvals) || []).reduce(
      (result, approval) => ({ ...result, [approval.id]: 'approved' }),
      {}
    )

  onSubmit = () => {
    const { showModal, closeModal, onStageFlyer, flyer, flyerReasons } = this.props
    const flyerId = idx(this.props, _ => _.flyer.fml) || ''

    const hasDeny = Object.values(this.state).some(
      status => status === 'denied'
    )

    const stagedFlyer = {
      flyerId,
      approvals: this.state,
    }

    if (hasDeny) {
      // @ts-ignore
      showModal(DenialReasonModalContainer, {
        closeModal,
        stagedFlyer,
        onStageFlyer,
        flyer: filter(DenialReasonModalContainer.fragments.flyer, flyer),
        flyerReasons: filter(DenialReasonModalContainer.fragments.flyerReasons, flyerReasons),
      })
    } else {
      onStageFlyer(stagedFlyer)
      closeModal()
    }
  };

  // TODO: is the the correct event type??
  handleApprovalStatusChange = (
    e: any
  ): void => {
    const status = idx(e, _ => _.currentTarget.dataset.value) || 'unknown'
    const id = idx(e, _ => _.currentTarget.dataset.id) || ''

    this.setState(state => ({
      ...state,
      [id]: status,
    }))
  };

  handleBulkApprovalStatusChange = (
    e: any
  ): void => {
    const status = idx(e, _ => _.currentTarget.dataset.value) || 'unknown'
    const newState = Object.keys(this.state).reduce(
      (result, approvalId) => ({ ...result, [approvalId]: status }),
      {}
    )

    this.setState(newState)
  };

  render() {
    const { closeModal } = this.props
    const approvals = idx(this.props, _ => _.flyer.approvals) || []
    const approvalCount = idx(approvals, _ => _.length) || 0

    // NOTE: this.state is all the stagedApprovals
    return (
      <Modal width="md" close={closeModal}>
        <Modal.Header>
          {`${approvalCount} School${approvalCount > 1 ? "s" : ""}`}
        </Modal.Header>
        <Modal.Body className="pt-3">
          <SchoolApprovalSelectionTable
            approvals={approvals}
            stagedApprovals={this.state}
            handleApprovalStatusChange={this.handleApprovalStatusChange}
            handleBulkApprovalStatusChange={
              this.handleBulkApprovalStatusChange
            }
          />
        </Modal.Body>
        <Modal.Footer className={classNames.footerShadow}>
          <ButtonFlatLarge
            onClick={closeModal}
            data-testId="click-cancel-schoolApproval"
          >
            Cancel
          </ButtonFlatLarge>
          <ButtonPrimaryLarge
            data-testId="submit-schoolApproval"
            onClick={this.onSubmit}
          >
            Save
          </ButtonPrimaryLarge>
        </Modal.Footer>
      </Modal>
    )
  }
}

const classNames = {
  footerShadow: css`
    box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.1);
    padding: 1rem;
    margin: 0 !important;
  `,
}

SchoolApprovalModal.fragments = {
  flyer: gql`
    fragment SchoolApprovalModal_flyer on Flyer {
      fml: id
      approvals {
        id
        schoolName
      }
      ...DenialReasonModalContainer_flyer
    }
    ${DenialReasonModalContainer.fragments.flyer}
  `,
  flyerReasons: gql`
    fragment SchoolApprovalModal_flyerReasons on FlyerReason {
      ...DenialReasonModalContainer_flyerReasons
    }
    ${DenialReasonModalContainer.fragments.flyerReasons}
  `,
}

const mapStateToProps = (state, props: OwnProps): ReduxStateProps => {
  const stagedFlyers = getStagedFlyers(state)
  const flyerId = idx(props, _ => _.flyer.fml) || ''
  return { stagedFlyerApproval: stagedFlyers[flyerId] }
}

const mapDispatchToProps = (dispatch: Dispatch): ReduxDispatchProps => ({
  onStageFlyer: stagedFlyer => {
    dispatch(setApprovalStatus(stagedFlyer))
  },
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SchoolApprovalModal)
