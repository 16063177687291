import 'core-js/stable'
import 'regenerator-runtime/runtime'

import React from 'react'
import { Provider as ReduxProvider } from 'react-redux'
import { ApolloProvider } from '@apollo/react-hooks'
import {
  createGenerateClassName,
  StylesProvider,
  ThemeProvider,
} from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import { LoggerApi, EventBrokerApi } from '@peachjar/kernel-sanders'
import { components } from '@peachjar/components'
import UserProvider from '../../middleware/context/UserProvider'
import apolloClient from '../../middleware/apollo/apolloClient'
import store from '../../middleware/redux/store/store'
import App from '../App/App'
import addKernelPropsToWindow from '../../utils/addKernelPropsToWindow'
import contentTheme from '../../themes/app.content.css.theme'
import constants from '../../config/constants'

declare global {
    interface Window { __PJ__: any }
}

type Props = {
  logger: LoggerApi,
  eventBroker: EventBrokerApi
}

const { ModalProvider } = components

const generateClassName = createGenerateClassName({
  productionPrefix: constants.APP_NAME
})

const ApplicationWrapper = (props: Props) => {
  const { logger, eventBroker } = props

  addKernelPropsToWindow(logger, eventBroker)

  return (
    <ReduxProvider store={store}>
      <ApolloProvider client={apolloClient}>
        <StylesProvider generateClassName={generateClassName}>
          <ThemeProvider theme={contentTheme}>
            <UserProvider>
              <ModalProvider>
                <CssBaseline />
                <App {...props} />
              </ModalProvider>
            </UserProvider>
          </ThemeProvider>
        </StylesProvider>
      </ApolloProvider>
    </ReduxProvider>
  )
}

export default ApplicationWrapper
