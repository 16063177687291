
import * as React from 'react'
import gql from 'graphql-tag'
import { Mutation } from 'react-apollo'
import { connect } from 'react-redux'
import { components } from '@peachjar/components'

import withLoading from '../withLoading/withLoading'
import { STRINGS } from '../../pages/AllApprovals/constants'
import { resetApprovals } from '../../pages/AllApprovals/approvals.duck'
import { getStagedFlyerApprovals } from '../../pages/AllApprovals/utils/selectors'

import { ApprovalForMutation } from '../../pages/AllApprovals/approvals.duck'

const { ButtonPrimaryLarge } = components.Buttons
const { notifySuccess, notifyError } = components.Notifications

const ButtonWithLoading = withLoading(ButtonPrimaryLarge)

const SUBMIT_PUBLICATION_REQUEST_REVIEWS = gql`
  mutation SubmitPublicationRequestReviews(
    $input: FlyerApprovalStatusUpdateInput!
  ) {
    updateFlyerApprovalStatus(input: $input)
  }
`

type ReduxDispatchProps = {
   handleSuccess: () => void,
   handleError: () => void,
}

type ReduxStateProps = {
   approvalsToSubmit: Array<ApprovalForMutation>,
}

type OwnProps = {
   disabled: boolean,
  children: React.ReactNode,
  sod: 'district' | 'school',
}

type Props =
  ReduxDispatchProps
  & ReduxStateProps
  & OwnProps

const SubmitPublicationRequestReviewsButton = ({
  handleSuccess,
  handleError,
  approvalsToSubmit,
  disabled,
  children,
  sod,
}: Props) => (
  <Mutation
    mutation={SUBMIT_PUBLICATION_REQUEST_REVIEWS}
    variables={{
      input: {
        sod,
        approvals: approvalsToSubmit,
      },
    }}
    refetchQueries={() => ['ViewerQuery']}
    onCompleted={handleSuccess}
    onError={handleError}
  >
    {(updateFlyerApprovalStatus, { loading }) => (
      <div>
        <ButtonWithLoading
          disabled={disabled}
          loading={loading}
          // @ts-ignore
          onClick={updateFlyerApprovalStatus}
        >
          {children}
        </ButtonWithLoading>
      </div>
    )}
  </Mutation>
)

const mapStateToProps = (state): ReduxStateProps => ({
  approvalsToSubmit: getStagedFlyerApprovals(state),
})

const mapDispatchToProps = (dispatch): ReduxDispatchProps => ({
  handleSuccess: () => {
    dispatch(
      notifySuccess(
        STRINGS.SUBMIT_FLYER_APPROVAL_STATUS_UPDATES_SUCCESS_MESSAGE
      )
    )
    dispatch(resetApprovals())
  },
  handleError: () => {
    dispatch(
      notifyError(STRINGS.SUMBIT_FLYER_APPROVAL_STATUS_UPDATES_ERROR_MESSAGE)
    )
  },
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SubmitPublicationRequestReviewsButton)
