import gql from 'graphql-tag'

export const GET_PENDING_APPROVALS_COUNT = gql`
  query getPendingApprovalsCount {
    sodUser {
      id
      approvalCenterRegions {
        id
        pendingApprovalCount
      }
    }
  }
`

export const GET_PENDING_APPROVALS = gql`
  query getPendingApprovals(
    $PendingApprovals_sodUser_sod: Sod
    $PendingApprovals_sodUser_sodId: ID
  ) {
    sodUser {
      id
      approvalCenterRegions {
        id
        type
        name
        districtId
        pendingApprovalCount
      }
      flyers: pendingFlyerApprovalRequests(
        sod: $PendingApprovals_sodUser_sod
        sodId: $PendingApprovals_sodUser_sodId
      ) {
        fml: id
        title
        description
        categoryId
        categoryName
        sellerId
        sellerType
        sellerEmail
        sellerUrl
        sellerPhone
        sellerNote
        sellerCompanyName
        sellerTaxId
        sellerTaxLodUrl
        submitDate
        postDate
        endDate
        eventDate
        districtPages {
          id
          pageNumber
          imageUrl
          cdnImageUrl
          pageCount
        }
        approvals {
          id
          lastStatus
          lastStatusUpdate
          schoolId
          districtId
          schoolName
          districtName
          noteAboutSeller
        }
      }
    }
    flyerReasons {
      code
      displayName
      suggestedVerbiage
      isDenial
      retiredAt
      isDeletion
    }
  }
`
