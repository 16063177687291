import React from 'react'
import { css, cx } from 'emotion'
// import InlineTip from './InlineTip'
import colors from '../colors'

// TODO: Move utils to shared

export const MIN_PASSWORD_LENGTH = 8
export const meetsLengthReq = (pw: string)  => pw.length >= MIN_PASSWORD_LENGTH

export const passesRegex = (regex: RegExp) => (pw: string) => regex.test(pw)
export const hasLowerRegex = /(?=.*[a-z])/
export const hasUpperRegex = /(?=.*[A-Z])/
export const hasDigitRegex = /(?=.*\d)/
export const checkHasLower = passesRegex(hasLowerRegex)
export const checkHasUpper = passesRegex(hasUpperRegex)
export const checkHasDigit = passesRegex(hasDigitRegex)

export const passesAllRequirements = (password: string) =>
  checkHasLower(password) &&
  checkHasUpper(password) &&
  checkHasDigit(password) &&
  meetsLengthReq(password)

const { slate, leaf, dragon, platinum } = colors

type CircleProps = {
  color: string
}

const Circle = ({ color }: CircleProps) => (
  <div className={classNames.circle} style={{backgroundColor: color }} />
)

type PWReqProps = {
  meetsReq: boolean
  label: string,
  hasError: boolean
}

const PWReq = ({ meetsReq, label, hasError }: PWReqProps) => {
  const errorColor = hasError ? dragon : 'gray'
  const circleColor = meetsReq ? leaf : errorColor
  return (
    <div className={`row ${classNames.itemThing}`}>
      <Circle color={circleColor} />
      {label}
    </div>
  )
}

type Props = {
  touched: boolean,
  focused: boolean,
  password: string,
  className?: string,
}

const PasswordRequirements = ({ touched, focused, password, className }: Props) => {
  const hasDigit = checkHasDigit(password)
  const hasLower = checkHasLower(password)
  const hasUpper = checkHasUpper(password)
  const meetsMinLength = meetsLengthReq(password)

  const hasError = touched && !focused

  return (
        <div className={cx(classNames.validator, className)}>
          <div className={`col-md-6 ${classNames.marginVertical}`}>
            <PWReq
              meetsReq={meetsMinLength}
              hasError={hasError}
              label="8 character minimum"
            />
            <PWReq
              meetsReq={hasDigit}
              hasError={hasError}
              label="1 number"
            />
          </div>
          <div className={`col-md-6 ${classNames.marginVertical}`}>
            <PWReq
              meetsReq={hasLower}
              hasError={hasError}
              label="1 lowercase letter"
            />
            <PWReq
              meetsReq={hasUpper}
              hasError={hasError}
              label="1 uppercase letter"
            />
          </div>
        </div>
  )
}

const classNames = {
  circle: css`
    width: 8px;
    height: 8px;
    border-radius: 4px;
    padding: 2px;
    margin-left: 4px;
    margin-right: 8px;
  `,
  itemThing: css`
    align-items: center;
    margin: 6px;
    color: ${slate};
    font-size: 13px;
    width: 170px;
  `,
  marginVertical: css`
    margin-top: 10px;
    margin-bottom: 10px;
  `,
  validator: css`
    margin-left: -5px;
    margin-bottom: 24px;
    background-color: ${platinum};
    display: flex;
    align-items: center;
  `
}

export default PasswordRequirements
