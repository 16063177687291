import { combineReducers } from 'redux'
import { components } from '@peachjar/components'
import { reducer as flyerApprovalsReducer } from '../../pages/AllApprovals/approvals.duck'

const {
  Notifications: { notificationsReducer }
} = components

const rootReducer = combineReducers({
  notifications: notificationsReducer,
  flyerApprovals: flyerApprovalsReducer
})

export default rootReducer
