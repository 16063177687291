
import * as React from 'react'
import idx from 'idx'
import gql from 'graphql-tag'
import { css, cx } from 'react-emotion'

import { colors, fonts } from '@peachjar/components'

import { ApprovalStatus } from '../../pages/AllApprovals/AllApprovals.types'
import DistrictStatusBarContent from '../DistrictStatusBarContent/DistrictStatusBarContent'
import SchoolStatusBarContent from '../SchoolStatusBarContent/SchoolStatusBarContent'

const getStatusCounts = (
  approvals: Array<{ id: string, status: ApprovalStatus }>
): {
  approved: number,
  denied: number,
  pending: number,
} =>
  approvals.reduce(
    (counts, nextApproval) => {
      const { status } = nextApproval
      return status === 'approved' || status === 'pending'
        ? { ...counts, [status]: counts[status] + 1 }
        : { ...counts, denied: counts.denied + 1 }
    },
    { approved: 0, pending: 0, denied: 0 }
  )

// TODO (Tech Debt): this flyer type should be generated from a GraphQL fragment or query
export type StatusBarFlyerFragment = {
  approvals: Array<{ id: string, status: ApprovalStatus }>,
}

type Props = {
  flyer: StatusBarFlyerFragment,
  isDistrict: boolean,
}

const HistoricalApprovalCardStatusBar = (props: Props) => {
  const { isDistrict } = props
  const approvals = idx(props, _ => _.flyer.approvals) || []

  const counts = getStatusCounts(approvals)
  // NOTE: if we can't find a status, we should probably error
  const statusForSchool = idx(approvals, _ => _[0].status) || null
  const shouldShowDistrictContent = isDistrict || approvals.length > 1

  return (
    <div className={cx('solution--stop-gap', classNames.pjApprovalBtn)}>
      {shouldShowDistrictContent ? (
        <DistrictStatusBarContent
          approvedCount={counts.approved}
          deniedCount={counts.denied}
          pendingCount={counts.pending}
        />
      ) : (
        // @ts-ignore
        <SchoolStatusBarContent status={statusForSchool} />
      )}
    </div>
  )
}

HistoricalApprovalCardStatusBar.fragments = {
  flyer: gql`
    fragment HistoricalApprovalCardStatusBar on Flyer {
      approvals {
        id
        status
      }
    }
  `,
}

const classNames = {
  pjApprovalBtn: css`
    &.solution--stop-gap {
      flex: 1 auto !important;
      font-family: ${fonts.ProximaNova};
      display: flex;
      align-items: center;
      width: 65%;
      text-transform: none;
      font-size: 1rem;
      line-height: 1.5;
      border: solid 1px ${colors.silver};
      border-bottom: 0px;
      border-radius: 0;
      color: ${colors.prussian} !important;
      padding: 1rem;
      margin: 0;
      &:last-child {
        border-bottom-right-radius: 6px !important;
      }
    }
  `,
}

export default HistoricalApprovalCardStatusBar
