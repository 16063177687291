import React from 'react'
import { css, cx } from 'emotion'
import Paper from '@material-ui/core/Paper'
import { withStyles } from '@material-ui/core/styles'

type Props = {
  children: React.ReactNode,
  classes: {
    [key: string]: any
  }
  classNames?: string
}

const ContentContainer = ({ children, classNames, classes }: Props) => (
  <Paper className={cx(cn.paper, classes.elevation2)}>
    { children }
  </Paper>
)

// Override default material-ui styles
const materialOverrides = {
  elevation2: {
    boxShadow: 'none',
    backgroundColor: 'transparent'
  },
}

const cn = {
  paper: css`
    padding: 24px 0 40px 0;
    min-height: 500px;

    &:hover {
      background-color: transparent !important;
    }
  `
}

export default withStyles(materialOverrides)(ContentContainer)
