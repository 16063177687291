import React from 'react'
import { useQuery } from '@apollo/react-hooks'
import UserContext from './UserContext'
import GET_USER from '../../components/App/App.graphql'
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner'
import handleGqlAuthError, { isGqlAuthError } from '../../utils/handleGqlAuthError/handleGqlAuthError'
import redirectTo from '../../utils/redirectTo/redirectTo'
import isApprovalCenterUser from '../../utils/isApprovalCenterUser/isApprovalCenterUser'
import config from '../../config/config'
import constants, { NOTIFICATIONS } from '../../config/constants'

type Props = {
  children: React.ReactNode
}

const { REACT_APP_GEOCORE_LOGIN_URL } = config
const { APP_NAME } = constants
const { genericError } = NOTIFICATIONS

const UserProvider = ({ children }): any => {
  const {
    loading: userLoading,
    data: userData,
    error: fetchUserError,
    refetch: refetchUser
  } = useQuery(GET_USER, {
    fetchPolicy: 'network-only'
  })

  if (userLoading) { return <LoadingSpinner /> }

  if (fetchUserError && isGqlAuthError(fetchUserError)) {
    handleGqlAuthError(fetchUserError)
    return null
  } else if (fetchUserError) {
    window.__PJ__.logger.captureWithScope(fetchUserError, {
      appName: APP_NAME,
      context: 'GET_USER query'
    })

    return genericError
  }

  if (!isApprovalCenterUser(userData.sodUser)) {
    redirectTo(REACT_APP_GEOCORE_LOGIN_URL)
    return null
  }

  return (
    <UserContext.Provider value={{ userData, refetchUser }}>
      { children }
    </UserContext.Provider>
  )
}

export default UserProvider
